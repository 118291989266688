<div class="p-d-flex mb-10">
        <button pButton
                pRipple
                type="button"
                icon="pi pi-plus"
                pTooltip="Yeni Ekle"
                tooltipPosition="top"
                label='Yeni Ekle'
                *ngIf='inquirySettings.isAdd'
                class="p-button-info p-mr-2 p-mb-2"
                [disabled]='!inquirySettings.hasAdd'
                (click)="addnew.emit()">
        </button>
        <button pButton
                pRipple
                type="button"
                [icon]="inquirySettings.Icon"
                [pTooltip]="inquirySettings.ExButtonTitle"
                tooltipPosition="top"
                [label]='inquirySettings.ExButtonTitle'
                *ngIf='inquirySettings.IsExButton && inquirySettings.IsPermissionState'
                class="p-button-info p-mr-2 p-mb-2"
                (click)="addNewExtra.emit()">
        </button>


        <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <span class="p-float-label">
                        <input id="float-input"
                               pInputText
                               #clientSearchInput
                               type="text"
                               (keyup)="globalFilter.emit(clientSearchInput.value)" />
                        <label for="float-input">Search</label>
                </span>
        </span>

        <button pButton
                pRipple
                type="button"
                icon="pi pi-file-excel"
                pTooltip="Excel Export"
                tooltipPosition="top"
                class="p-button-rounded p-button-warning p-button-text p-button-lg"
                (click)="excelExport.emit(grid)"></button>

        <button pButton
                pRipple
                type="button"
                icon="pi pi-refresh"
                pTooltip="Refresh Data"
                tooltipPosition="top"
                class="p-button-rounded  p-button-success p-button-text p-button-lg"
                (click)="refresh.emit()"></button>

</div>