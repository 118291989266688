import { Injectable } from "@angular/core";
import { ConfirmationService } from "primeng/api";

@Injectable()
export class ConfirmService {
    constructor(private confirmationService: ConfirmationService) {}

    confirmDelete(): Promise<boolean> {
        let confirmObservable = new Promise<boolean>(
            (resolve,reject) => {
                this.confirmationService.confirm({
                    key: "confirm1",
                    header: "Uyarı!",
                    message: "Bu Kaydı Silmek İstediğinizden Emin misiniz?",
                    accept: () => {
                        resolve(true);
                    },
                    reject: () => {
                        reject(false);
                    },
                });
            }
        );

        return confirmObservable;
    }
    confirmByMessage(msg:string): Promise<boolean> {
        let confirmObservable = new Promise<boolean>(
            (resolve,reject) => {
                this.confirmationService.confirm({
                    key: "confirm1",
                    header: "Uyarı!",
                    message: msg,
                    accept: () => {
                        resolve(true);
                    },
                    reject: () => {
                        reject(false);
                    },
                });
            }
        );

        return confirmObservable;
    }
}
