<p-card class="mt-10 block"
        *ngIf="editGridInfo">
    <kendo-grid #grid
                [data]="data"
                [sortable]="true"
                (edit)="editHandler($event)"
                (cancel)="cancelHandler($event)"
                (save)="saveHandler($event)"
                (remove)="removeHandler($event)"
                (add)="addHandler($event)"
                [navigable]="true">


        <ng-template kendoGridToolbarTemplate>
            <button kendoGridAddCommand
                    [disabled]="disabled">Ekle</button>
                    
        </ng-template>

        <ng-container *ngFor="let columnInfo of editGridInfo.columnsInfo">

            <kendo-grid-column [field]="columnInfo.key"
                               [editor]="columnInfo.editor"
                               [title]="columnInfo.title"
                               [editable]="columnInfo.isEditable"
                               [format]="columnInfo.format"
                               [width]="columnInfo.width" >
                              

         <ng-container *ngIf="columnInfo.editor === 'text' ">
               <ng-container *ngIf="columnInfo.mask">
                   <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem[columnInfo.key] }}</ng-template>
                      <ng-template kendoGridEditTemplate
                                            let-dataItem="dataItem"
                                            let-formGroup="formGroup">
                            <kendo-maskedtextbox                                         
                                        [value]="formGroup.get(columnInfo.key).value"
                                        [mask]="columnInfo.mask"
                                        [formControl]="formGroup.get(columnInfo.key)"> 
                            </kendo-maskedtextbox>

                            </ng-template>
                   </ng-container>
            </ng-container>

             <ng-container *ngIf="columnInfo.editor === 'date'">
                        <ng-template kendoGridCellTemplate
                        let-dataItem>{{ dataItem[columnInfo.key] | utcDateTime2 }}</ng-template>
                        <ng-template kendoGridEditTemplate
                                           let-dataItem="dataItem"
                                             let-formGroup="formGroup"
                                            >
                                  <kendo-datepicker #picker
                                                    [formControl]="formGroup.get(columnInfo.key)"
                                                    [format]="'dd.MM.yyyy'"
                                                    placeholder=""
                                                    [value]="formGroup.get(columnInfo.key).value"></kendo-datepicker>
                                        <!-- <kendo-popup
                                        [anchor]="formGroup.get(columnInfo.key)"
                                        *ngIf="formGroup.get(columnInfo.key).invalid"
                                        popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
                                        >
                                        <span class="k-icon k-i-warning"></span>
                                       Date is required
                                        </kendo-popup> -->
                                </ng-template>
               </ng-container>

                
                <ng-container *ngIf="columnInfo.editor === 'dropdown'">
                    <ng-template kendoGridCellTemplate
                                 let-dataItem>
                        {{ dataItem[columnInfo.dropDownInfo.selectedItemFieldName] }}
                    </ng-template>

                    <ng-template kendoGridEditTemplate
                                 let-dataItem="dataItem"
                                 let-column="column"
                                 let-formGroup="formGroup"
                                 let-rowIndex="rowIndex">
                        <kendo-dropdownlist *ngIf="showDropDowns"
                                            [data]="columnInfo.dropDownInfo.options"
                                            [textField]="columnInfo.dropDownInfo.textField"
                                            [valueField]="columnInfo.dropDownInfo.valueField"
                                            [valuePrimitive]="columnInfo.dropDownInfo.valuePrimitive"
                                            [formControl]="formGroup.get(columnInfo.key)"
                                            [filterable]="true"
                                            [optionLabel]="columnInfo.dropDownInfo.selectedItemFieldName"
                                            (filterChange)="onDropDownFilterChange($event,columnInfo,dataItem)"
                                            (selectionChange)="dropDownSelectionChanged($event,dataItem,columnInfo,rowIndex)">
                        </kendo-dropdownlist>

                    </ng-template>
                </ng-container>

                
            </kendo-grid-column>

        </ng-container>




        <kendo-grid-command-column title="command" witdh="30"
                                   class="command_buttons">
            <ng-template kendoGridCellTemplate
                         let-isNew="isNew">

                <button kendoGridEditCommand
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-pencil"
                        [disabled]='disabled'
                        class="p-button-rounded p-button-text"></button>

                <button kendoGridRemoveCommand
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        [disabled]='disabled'
                        class="p-button-rounded p-button-danger p-button-text"></button>

                <button kendoGridSaveCommand
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-save"
                        [disabled]='disabled  || formGroup?.invalid'
                        class="p-button-rounded p-button-text"></button>


                <button kendoGridCancelCommand
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-times"
                        [disabled]='disabled'
                        class="p-button-rounded p-button-danger p-button-text"></button>
            </ng-template>
        </kendo-grid-command-column>
    </kendo-grid>


</p-card>



<p-confirmDialog key="reomveDialog"
                 icon="pi pi-exclamation-triangle"
                 acceptButtonStyleClass="p-button-text"
                 rejectButtonStyleClass="p-button-text"></p-confirmDialog>