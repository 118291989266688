import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { USER_INFO } from 'src/app/shared/authorization/user-info';
import { CustomerDashboardEntity } from '../model/dashboard.model';
import { DashboardService } from '../service/dashboard.service';
import * as introJs from 'intro.js/intro.js';
import { RoleConstansEnum } from 'src/app/modules/constants/action.enum';

@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.component.html',
    styleUrls: ['./customer-dashboard.component.scss'],
    providers: [DashboardService],

})
export class CustomerDashboardComponent implements OnInit {

    entity: CustomerDashboardEntity;

    overviewChartData: any;
    overviewChartOptions: any;
    ordersOptions: any;
    lineOptions: any;
    introJS = introJs();

    constructor(public app: AppComponent, private service: DashboardService) {
        this.entity = new CustomerDashboardEntity();
    }

    ngOnInit(): void {

        this.getCustomerDashboard();
        this.lineOptions = this.getChartOptions();
        let showIntro = localStorage.getItem('showIntro');
        let roleId = USER_INFO.info.RoleId;

        if (showIntro == 'E' && roleId == RoleConstansEnum.CustomerManager)
            this.initIntroJs();

    }

    async getCustomerDashboard() {
        await this.service.GetCustomerDashboard().then(res => {
            if (res.ReturnStatus) {
                this.entity = res.Entity;
                // this.getOverviewChart();

            }
        })
    }
    getOverviewChart() {

        this.overviewChartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
            datasets: [
                {
                    data: [11, 30, 52, 35, 39, 20, 14, 18, 29],
                    borderColor: [
                        '#4DD0E1',
                    ],
                    backgroundColor: [
                        'rgba(77, 208, 225, 0.8)',
                    ],
                    borderWidth: 2,
                    fill: true
                }
            ]
        };

        this.overviewChartOptions = {
            legend: {
                display: false
            },
            responsive: true,
            scales: {
                yAxes: [{
                    display: false
                }],
                xAxes: [{
                    display: false
                }]
            },
            tooltips: {
                enabled: false
            },
            elements: {
                point: {
                    radius: 0
                }
            },
        };

    }
    getChartOptions() {
        const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
        const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
        const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
        return {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        fontFamily,
                        fontColor: textColor,
                    }
                }
            },
            responsive: true,
            scales: {
                y: {
                    ticks: {
                        fontFamily,
                        color: textColor
                    },
                    grid: {
                        color: gridLinesColor
                    }
                },
                x: {
                    ticks: {
                        fontFamily,
                        color: textColor
                    },
                    grid: {
                        color: gridLinesColor
                    }
                }
            }
        };
    }

    //********************************* INTRO JS **********************************/
    initIntroJs(): void {
        this.introJS
            .setOptions({
                steps: [
                    {
                        // element: document.getElementsByClassName('p-fluid')[0],
                        intro: '<p>Vergi Global Sgk Teşvik Hesaplama Programına Hoşgeldniz.</p>',
                        position: 'bottom'
                    },
                    {
                        element: document.getElementById('tesvikliPersSay'),
                        intro: '<p>Anasayfa Ekranında Teşvikli Personel Sayısını Görebilirsiniz.</p>',
                        position: 'bottom'
                    },
                    {
                         element: document.getElementById('birSonrakiDonemEleman'),
                        intro: '<p> Bir Sonraki Dönem için Teşvikli Personel Sayısı. </p>',
                        position: 'bottom'
                    },
                    {
                        element: document.getElementById('toplamTesvikTutari'),
                        intro: '<p> <strong>" Toplam Teşvik Tutarını Görebilirsiniz.</p>',
                        position: 'left'
                    },
                    {
                         element: document.getElementById('personelAnalizGrafik'),
                        intro: '<p>Personel Son Dönemlerdeki Aylık Grafiğine Buradan Bakabilirsiniz.</p>',
                        position: 'bottom'
                    },
                    
                    {
                         element: document.getElementById('tesvikAnalizGrafik'),
                        intro: '<p>Teşvik Analiz Grafiği .</p>',
                        position: 'bottom'
                    },
                    {
                         element: document.getElementsByClassName('layout-menu')[0],
                         intro: '<p>' +
                         '1.Bu Kısımdan Bilgileriniz Güncelleme,İş Yeri Ekleme, Hesaplama Yapma ve Faturalarınızı Görüntüleme İşlerini Yapabilirsiniz<br></p>',
                         position: 'bottom'
                    },
                ],
                dontShowAgain:true,
                dontShowAgainLabel:'Bu Tanıtımı Bir Daha Gösterme!',
                dontShowAgainCookie:'CustomerDashboardIntro',        
                showProgress: true,
                prevLabel: 'Önceki',
                nextLabel: 'Sonraki',
                doneLabel: 'Kapat'
            
            }).start();
    }

}

