import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AgencyDashboardEntity } from '../model/dashboard.model';
import { DashboardService } from '../service/dashboard.service';

@Component({
  selector: 'app-agency-dashboard',
  templateUrl: './agency-dashboard.component.html',
  styleUrls: ['./agency-dashboard.component.scss'],
  providers: [DashboardService],

})
export class AgencyDashboardComponent implements OnInit {

    
    entity: AgencyDashboardEntity;

    overviewChartData: any;
    overviewChartOptions: any;

    constructor(public app: AppComponent, private service: DashboardService) { }

    ngOnInit(): void {
        this.getAgencyDashboard();
    }

    async getAgencyDashboard() {
        await this.service.GetAgencyDashboard().then(res => {
            if (res.ReturnStatus) {
                this.entity = res.Entity;
                this.getOverviewChart();
            }
        })
    }
  
  getOverviewChart()
  {
      
    this.overviewChartData= {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
        datasets: [
            {
                data: [11, 30, 52, 35, 39, 20, 14, 18, 29],
                borderColor: [
                    '#4DD0E1',
                ],
                backgroundColor: [
                    'rgba(77, 208, 225, 0.8)',
                ],
                borderWidth: 2,
                fill: true
            }
        ]
    };

    this.overviewChartOptions = {
        
        // legend: {
        //     display: false
        // },
        // responsive: true,
        // scales: {
        //     yAxes: [{
        //         display: false
        //     }],
        //     xAxes: [{
        //         display: false
        //     }]
        // },
        // tooltips: {
        //     enabled: false
        // },
        // elements: {
        //     point: {
        //         radius: 0
        //     }
        // },
    };

  }


}
