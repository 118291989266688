import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { saveAs as importedSaveAs } from "file-saver";
import { AlertService } from './alert.service';
import { FileQueueObject, FileResponseModel, FileSearchModel } from '../model/file/file-model';
import { Observable } from 'rxjs';
import * as FileSaver from "file-saver";
import { BynFilesEntity } from "src/app/modules/calculation-management/calcmodel/byn-files.model";


// tslint:disable-next-line:max-classes-per-file
@Injectable({ providedIn: 'root' })
export class FileService {

    public postUrl: string = environment.apiUrl + "file/upload";
    public downloadUrl: string = environment.apiUrl + "File/DownloadFile";
    public downloadBynUrl: string = environment.apiUrl + "File/DownloadBynFile";

    constructor(private http: HttpClient,
        private alertService: AlertService) { }


    public downloadFile(queueObj: FileQueueObject) {
        return this.http.post(`${this.downloadUrl}?fname=${queueObj.fileName}`, {}, { responseType: 'blob' as 'json' }).subscribe((respData: Blob) => {
            var blob = new Blob([respData], { type: respData.type });
            var url = window.URL.createObjectURL(blob);
            importedSaveAs(blob, queueObj.fileName);

        }, error => {
            this.alertService.ErrorMessage("Dosya İndirilemiyor.!!!");
        });
    }
    public downloadBynFile(queueObj: BynFilesEntity) {
        return this.http.post(`${this.downloadBynUrl}?fname=${queueObj.FName}`, {}, { responseType: 'blob' as 'json' }).subscribe((respData: Blob) => {
            var blob = new Blob([respData], { type: respData.type });
            var url = window.URL.createObjectURL(blob);
            importedSaveAs(blob, queueObj.FName);

        }, error => {
            this.alertService.ErrorMessage("Dosya İndirilemiyor.!!!");
        });
    }

    uploadFile(files: FileQueueObject[]):Observable<FileResponseModel> {
        return this.http.post<FileResponseModel>(environment.apiUrl + "File/Upload", files);
    }

    removeMyFiles(item: FileQueueObject):Observable<FileResponseModel> {
        return this.http.post<FileResponseModel>(environment.apiUrl + "File/removeFiles", item);
    }
    
    getMyFiles(searchModel: FileSearchModel):Observable<FileResponseModel> {
        return this.http.post<FileResponseModel>(environment.apiUrl + "File/getFiles", searchModel);
    }
    public _download(downloadUrl:string,explanation:string) {

        const httpOptions = {
          responseType: 'blob' as 'blob'
      
        };
        return this.http.post(downloadUrl,"",httpOptions).subscribe(respData => {
          var blob = new Blob([respData], { type: respData.type });
        // saveAs(blob, explanation);
        FileSaver(blob, explanation);
      
        }, error => {
      
            this.alertService.ErrorMessage("Dosya İndirilemiyor.!!!");
      
        });
    }

}
