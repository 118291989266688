<div class="p-grid dashboard">

    <div class="p-col-12 p-md-12 p-lg-4">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="pi pi-users"></i>
                <!-- <i class="pi pi-wallet"></i> -->
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Teşvikli Personel Sayısı</h6>
            </div>
            <div id="tesvikliPersSay" class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="mb-1 fs-xlarge">{{this.entity?.TesvikAlanPers}}</span>
                    <span class="overview-status p-p-1 teal-bgcolor fs-small">{{ this.entity?.TesvikAlanPers }}
                        Teşvikli Personel Sayısı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <div id="birSonrakiDonemEleman" class="p-col-12 p-md-6 p-lg-4">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="pi pi-users"></i>
                <!-- <i class="fa fa-fw fa-sitemap"></i> -->
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Bir Sonraki Dönem Teşvik İçin Gerekli Eleman
                </h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge">{{ this.entity?.SonrakiDonemPers }}</span>
                    <span class="overview-status p-p-1 orange-bgcolor fs-small">{{ this.entity?.SonrakiDonemPers }}
                        Gerekli Eleman</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <div id="toplamTesvikTutari" class="p-col-12 p-md-6 p-lg-4">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
            <div class="p-d-flex p-ai-center muted-text">
                <!-- <i class="pi pi-users"></i> -->
                <i class="pi pi-wallet"></i>
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Toplam Teşvik Tutarı</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="mb-1 fs-xlarge">{{this.entity?.ToplamTesvikTutar}}</span>
                    <span class="overview-status p-p-1 teal-bgcolor fs-small">{{ this.entity?.ToplamTesvikTutar }}
                        Toplam Teşvik Tutarı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <!--Chart Started-->


    <div id="personelAnalizGrafik" class="p-col-12 p-md-6 p-lg-6">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
            <div class="card-header">
                <h5>Personel Analiz Grafiği</h5>
            </div>
            <p-chart type="line"
                     [data]="this.entity?.persGraphData"
                     [options]="lineOptions"></p-chart>
        </div>
    </div>

    <div id="tesvikAnalizGrafik" class="p-col-12 p-md-6 p-lg-6">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">


            <div class="card-header">
                <h5>Teşvik Analiz Grafiği</h5>
            </div>
            <p-chart type="line"
                     [data]="this.entity?.tesvikGraphData"
                     [options]="ordersOptions"></p-chart>
        </div>
    </div>

</div>
