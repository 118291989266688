import { Component } from "@angular/core";

@Component({
  template: `
<div style="
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;">
  <img src="assets/images/loading/three-dots-loading-white.svg" />
</div>
    `,
})
export class BlockUITemplateComponent { }
