import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppNotfoundComponent } from './pages/structure/app.notfound.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AppMainComponent } from './pages/structure/app.main.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardGuard } from './core/guards/dashboard.guard';
import { AppAccessdeniedComponent } from './pages/app-acces/app.accessdenied.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                    { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardGuard] },
                    {
                        path: 'appmanagement',
                        loadChildren: () => import('./modules/appmanagement/app-management.module').then(m => m.AppManagementModule)
                    },
                    {
                        path: 'agencymanagement',
                        loadChildren: () => import('./modules/agency-management/agency-management.module').then(m => m.AgencyManagementModule)
                    },
                    {
                        path: 'companymanagement',
                        loadChildren: () => import('./modules/company-management/company-management.module').then(m => m.CompanyManagementModule)
                    },
                    {
                        path: 'parametersmanagement',
                        loadChildren: () => import('./modules/parameter-management/parameter-management.module').then(m => m.ParameterManagementModule)
                    },
                    {
                        path: 'contractmanagement',
                        loadChildren: () => import('./modules/contract-management/contract-management.module').then(m => m.ContractManagementModule)
                    },
                    {
                        path: 'workflowmanagement',
                        loadChildren: () => import('./modules/workflow-management/workflow-management.module').then(m => m.WorkFlowManagementModule)
                    },
                    {
                        path: 'calculationmanagement',
                        loadChildren: () => import('./modules/calculation-management/calculation-management.module').then(m => m.CalculationManagementModule)
                    },
                    {
                        path: 'reportmanagement',
                        loadChildren: () => import('./modules/report-management/report-management.module').then(m => m.ReportManagementModule)
                    },
                    {
                        path: 'reportdesigner',
                        loadChildren: () => import('./modules/report-designer/report-designer.module').then(m => m.ReportDesignerModule)
                    },
                    {
                        path: 'announcementmanagement',
                        loadChildren: () => import('./modules/announcement-management/announcement-management.module').then(m => m.AnnouncementManagementModule)
                    },
                    {
                        path: 'invoicemanagement',
                        loadChildren: () => import('./modules/invoice-management/invoice-management.module').then(m => m.InvoiceManagementModule)
                    },
                    { 
                        path: 'demosendermanagement',
                        loadChildren: () => import('./modules/demo-management/demosender-management/demosender-management.module').then(m => m.DemoSenderManagementModule)
                    },
                    { 
                        path: 'helpermanagement',
                        loadChildren: () => import('./modules/helper-management/helper-management.module').then(m => m.HelperManagementModule)
                    },


                ],
                canActivate: [AuthGuard]
            },
            { path: 'democalcmanagement',loadChildren: () => import('./modules/demo-management/democalc-management/demo-management.module').then(m => m.DemoCalcManagementModule)},
            { path: 'authentication', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule) },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'access', component: AppAccessdeniedComponent },

            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
