import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouteReuseStrategy, RouterStateSnapshot } from '@angular/router';
import { BaseService } from 'src/app/base/services/base-service';


@Injectable()
export class InquiryResolverService implements Resolve<any>{
    constructor() { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const service: BaseService<any, any> = route.data.service;
        let data: { inquirySettings: any } = { inquirySettings: {} };
        await service.inquirySettings().then(result => {
            data.inquirySettings = result.ReturnStatus ? result.Entity : undefined;
        });

        return data;
    }
}