

export enum ROLESCONSTANTSENUM
{
 Admin_100=100,
 SuperUser_101=101,

 Agency_120=120,
 CustomerManager_130=130,
 CustomerEditor_131=131,

 Approver_140=140,
 Controller_150=150,
 Advisor_160=160,
 AdvisorEditor_170=170,

}