import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'utcDateTime2'
})
export class UtcDateTime2Pipe implements PipeTransform {
    transform(value:Date): Date |string | null {
        if(!value)
            return "";      
        return formatDate(value,"short","tr-TR","UTC");       
            
    }
}