import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionResponse } from 'src/app/base/models/base-response.model';
import { environment } from 'src/environments/environment';
import { AgencyDashboardEntity, CustomerDashboardEntity, DashboardAnnouncementEntity } from '../model/dashboard.model';

@Injectable()
export class DashboardService {
  controllerName = 'Dashboard';
  apiUrl = environment.apiUrl;

constructor(private http:HttpClient) {
}

  async GetCustomerDashboard<T>(): Promise<ActionResponse<CustomerDashboardEntity>> {
    return await this.http.get<ActionResponse<CustomerDashboardEntity>>(`${this.apiUrl}${this.controllerName}/GetCustomerDashboard/`).toPromise();
  }
  async GetAgencyDashboard<T>(): Promise<ActionResponse<AgencyDashboardEntity>> {
    return await this.http.get<ActionResponse<AgencyDashboardEntity>>(`${this.apiUrl}${this.controllerName}/GetAgencyDashboard/`).toPromise();
  }
  async GetAnnouncement<T>(): Promise<ActionResponse<DashboardAnnouncementEntity>> {
    return await this.http.get<ActionResponse<DashboardAnnouncementEntity>>(`${this.apiUrl}${this.controllerName}/GetAnnouncement/`).toPromise();
  }

  

}
