import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ActionResponse } from "src/app/base/models/base-response.model";
import { UsersEntity } from "src/app/modules/appmanagement/users/model/users.model";
import { environment } from "src/environments/environment";
import { USER_INFO } from "../authorization/user-info";
import { UserModel } from "../authorization/user-model";
import { AppMenuItem, MenuItems } from "../menu/menu-items";

@Injectable()
export class AuthService {

    constructor(private http: HttpClient, private router: Router) {

    }

    public startSession() {
        const token: string = localStorage.getItem('token');
        const jwtHelperService: JwtHelperService = new JwtHelperService();
        const decodedToken = jwtHelperService.decodeToken(token);
        USER_INFO.info.SelselectedRoles = [];
        USER_INFO.info.Token = token;
        USER_INFO.info.FirstName = decodedToken.given_name;
        USER_INFO.info.LastName = decodedToken.nameid;
        USER_INFO.info.Email = decodedToken.email;
        USER_INFO.info.Id = decodedToken.primarysid;
        USER_INFO.info.UserName = decodedToken.unique_name;
        USER_INFO.info.HasRole = decodedToken.role;
        USER_INFO.info.RoleId = decodedToken.RoleId
        USER_INFO.info.AgencyId = decodedToken.AgencyId
        USER_INFO.info.CustomerManagerId = decodedToken.CustomerManagerId
        USER_INFO.info.CustomerEditorId = decodedToken.CustomerEditorId
        USER_INFO.info.isAuthenicated = true;
        USER_INFO.info.TokenExpirationDate = jwtHelperService.getTokenExpirationDate(token);
    }


    async getMenuForUser(): Promise<ActionResponse<AppMenuItem>> {
        return await this.http.get<ActionResponse<AppMenuItem>>(`${environment.apiUrl}Menu/GetRolesMenu2/${USER_INFO.info.RoleId}`)
            .pipe(tap((res: any) => {
                MenuItems.menuData = res.Entity.menuData
            })).toPromise();
    }

    login(loginData: { UserName: string, Password: string }): Observable<ActionResponse<UserModel>> {
        return this.http.post<ActionResponse<UserModel>>(`${environment.apiUrl}Login/Login`, loginData);
    }

    logOut() {
        localStorage.removeItem('token');
        this.router.navigate([`/authentication/login`]);
    }
    async ForgotPassword<T>(email: string): Promise<ActionResponse<boolean>> {
        return this.http.get<ActionResponse<boolean>>(`${environment.apiUrl}Login/ForgotPassword/${email}`).toPromise();
    }
    async GetPassChangeUser<T>(token: string): Promise<ActionResponse<UsersEntity>> {
        return this.http.get<ActionResponse<UsersEntity>>(`${environment.apiUrl}Login/GetPassChangeUser/${token}`).toPromise();
    }
    async ChangePassword<T>(entity: UsersEntity): Promise<ActionResponse<boolean>> {
        return this.http.post<ActionResponse<boolean>>(`${environment.apiUrl}Login/ChangePassword/`, entity).toPromise();
    }


}
