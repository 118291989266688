import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouteReuseStrategy, RouterStateSnapshot } from '@angular/router';
import { MaintenanceSettings } from 'src/app/base/models/maintenance-settings.model';
import { BaseService } from 'src/app/base/services/base-service';


@Injectable()
export class MaintananceResolverService implements Resolve<any>{
    constructor(public injector: Injector) { }
    router = this.injector.get(Router);

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const service: BaseService<any, any> = route.data.service;
        let data: { maintenanceSettings: MaintenanceSettings, entity: {} } = { maintenanceSettings: new MaintenanceSettings(), entity: {} };

        await service.getMaintenanceSettings().then(result => {
            if(result.ReturnStatus){
            data.maintenanceSettings =  result.Entity ;
            }
            else
            {
            
            }
            
        });

        if (route.params.id) {
            await service.getEntity({ [data.maintenanceSettings.IdentifierField]: route.params.id, isLoaded: false }).
            then(result => {
                if(result.ReturnStatus){
                    data.entity  =  result.Entity ;
                    }
                    else
                    {
                        this.router.navigate([`/notfound`]);
                    }

            });
        } else {
            data.entity = { [data.maintenanceSettings.IdentifierField]: 0, isLoaded: false, isEditState: true }
        }

        return data;
    }
}