//*************** Calculation Action *************/

export enum CalculationAction {
  SgkDefaultCalc = 1,
  SgkBeginCalc = 2,
  BeyannameDefaultCalc = 3,
  BeyannameBeginCalc = 4,
  XmlDefaultCalc = 5,
  XmlBeginCalc = 6,
  DemoDefaultCalc = 7,
  DemoBeginCalc = 8,
  SearchCustomer = 9,
  SgkShowErrorLog = 10,
  XmlShowErrorLog = 11,
  BynShowErrorLog = 12,
  Recalc = 13,
  AllRecalc = 14,
  CopyJobId = 15,

}
//*************** Demo Action *************/

export enum DemoSenderAction {
  GetCustomerInfo = 1,
  AddCustomer=2,
  ClearCustomerForm=3,
  ShowDialogCustomer = 5,
  HideCustomerDialog = 8,



}
//*************** Role Contstans *************/

export enum RoleConstansEnum {

  Admin = 100,
  SuperUser = 101,
  Agency = 120,
  CustomerManager = 130,
  CustomerEditor = 131,
  Approver = 140,
  Controller = 150,
  Advisor = 160,
  AdvisorEditor = 170,
}

//*************** Company Action *************/

export enum CompanyActions {
  AgencyChange = 1,
  CustomerChange = 2,
  CityChange = 3,

  //**
  DisplayExcelDialog = 4,
  ControlExcelData = 5,
  SaveExcelData = 6,
  PreviousStep = 7,
  CloseExcelDialog = 8,
  SearchCustomer = 9,

}

//*************** Jobs Action *************/

export enum JobsPanelActions {
  CreateInvoice = 1,
}


//*************** Customer Action *************/

export enum CustomerActions {

  //** Header Forms */

  AddCustomerUser = 1,
  UpdateCustomerUser = 2,
  DeleteCustomerUser = 3,
  ClearUserItems = 4,
  ShowDialogUser = 5,
  OnCellClickUser = 6,
  SelectedRowChangeUser = 7,
  HideUserDialog = 8,

}

//*************** Agency Action *************/

export enum AgencyActions {

  AddAgencyUser = 1,
  UpdateAgencyUser = 2,
  DeleteAgencyUser = 3,
  ClearUserItems = 4,
  ShowDialogUser = 5,
  OnCellClickUser = 6,
  SelectedRowChangeUser = 7,
  HideUserDialog = 8,

}

//*************** Demo Calc Action *************/

export enum DemoCalcActions {

  CloseExcelDialog = 5,

}


//*************** Contract Req Action *************/
export enum ContractReqAction {
  AgencyChange = 1,
  CustomerChange = 2,
  ContractChange = 3,

}

//*************** Hesaplama Tipi *************/
export enum HesaplamaTipi {
  SgkdanHesaplama = "1",
  BeyannameliHesaplama = "2",
  XMLliHesaplama = "3",
  DemoHesaplama = "4"

}

//*************** Hizmet Listesi *************/
export enum HizmetListesi {
  GetDetail = 1,
  GetPersons = 2,
  HidePersons = 3,
  OpenHizmetListesi = 4,

}

export enum ApStatEnum {  
  Aktif = "A",
  Pasif = "P",
}
