<div class="p-col-12 p-grid">


	<p-dialog header="Dosya Ekleme"
			  [(visible)]="display"
			  modal="true"
			  showEffect="fade"
			  styleClass="mydialog"
			  [style]="{width: '30vw'}">



		<div class="p-col-12">
			<p-messages [value]="msgs"></p-messages>
			<div class="p-col-12 col-md-8">
				<p-dropdown appendTo="body"
							[style]="{width: '100%'}"
							*ngIf="categoryList"
							[options]="categoryList"
							[showTransitionOptions]="'0ms'"
							[hideTransitionOptions]="'0ms'"
							placeholder="Select Type"
							optionLabel="label"
							[(ngModel)]="selectedCategory"
							filter="true"
							appendTo="body"
							[showClear]="true">
				</p-dropdown>
			</div>
			<div class="p-col-12 col-md-4">
				<label><i class="pi pi-file-pdf"></i> Upload
					<input type="file"
						   #fileInput
						   multiple
						   (change)="Upload($event)" />
				</label>
			</div>

		</div>
	</p-dialog>

	<div class="p-col-2">

		<p-button *ngIf="isAdFile"
		          label="{{fileDesc}}" 
				  pTooltip="{{fileDesc}}"
				  tooltipPosition="top"
				  [disabled]="!isEditState"
				  (click)="display=true"
				  icon="pi pi-plus"
				  styleClass="p-button-outlined p-button-success p-mr-2 p-mb-2 p-button-sm">
		</p-button>
	</div>

	<div class="p-col-10">


		<p-table [value]="fileList"
				 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm">
			<ng-template pTemplate="header">
				<tr>
					<!-- <th *ngIf="categoryList" class="text-left">Kategori</th> -->
					<th *ngIf="categoryList"
						class="text-left">Kategori</th>
					<th style="width: 50%;">Dosya Adı</th>
					<th>Dosya Boyutu</th>
					<th>Ekleyen Kişi</th>
					<th>İşlem</th>

				</tr>
			</ng-template>
			<ng-template pTemplate="body"
						 let-item>
				<tr [pSelectableRow]="item">
					<td *ngIf="categoryList">{{ item.categoryName }}</td>
					<td>{{ item.ftitle }}</td>
					<td class="text-right">{{ item.size }} B</td>
					<td>{{ item.owner}}</td>
					<td>
						<button pButton
								pRipple
								*ngIf="recid"
								type="button"
								icon="pi pi-download"
								class="p-button-outlined"
								pTooltip="Dosyayı İndir"
								tooltipPosition="top"
								(click)="download(item)"
								[ngClass]="{'p-ml-auto p-mr-1': !app.isRTL, 'p-mr-auto p-ml-1': app.isRTL}"></button>

						<button pButton
								pRipple
								type="button"
								icon="pi pi-trash"
								class="p-button-outlined"
								pTooltip="Dosyayı Sil"
								tooltipPosition="top"
								(click)="remove(item)"
								[disabled]="!isEditState"></button>
					</td>
				</tr>
			</ng-template>
		</p-table>

	</div>
</div>