import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { USER_INFO } from 'src/app/shared/authorization/user-info';
import { RoleConstansEnum } from 'src/app/modules/constants/action.enum';
import { MenuItems } from 'src/app/shared/menu/menu-items';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppTopBarComponent implements OnInit {

    urlBase: string;
    isShowIntro: boolean;
    activeItem: number;
    username: string;
    isCustomer: boolean = false;
    items: MenuItem[];
    model: any[] = MenuItems.menuData;

    constructor(public appMain: AppMainComponent, public app: AppComponent, private authService: AuthService, private router: Router,) {

        this.username = USER_INFO.info.FirstName + " " + USER_INFO.info.LastName;
        this.urlBase = 'dashboard';
        if (USER_INFO.info.RoleId == RoleConstansEnum.CustomerManager)
            this.isCustomer = true;

        if (localStorage.getItem('showIntro') == 'E')
            this.isShowIntro = true;
        else
            this.isShowIntro = false;
    }
    ngOnInit(): void {
        
        this.items=this.model;
    }


    

    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    onSearchAnimationEnd(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }


    logout() {
        this.authService.logOut();
    }
    changePassw() {
        this.router.navigate(['/appmanagement/users/changePassword']);
    }
    changeIntro(event) {
        if (!event.checked)
            localStorage.setItem('showIntro', 'H');
        if (event.checked)
            localStorage.setItem('showIntro', 'E');

    }
}
