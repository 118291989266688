import {
  Component,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  OnDestroy,
  Renderer2,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridComponent, CellClickEvent, EditEvent } from '@progress/kendo-angular-grid';
import { RowActionEvent } from '../../../model/grid/row-action.model';
import { MenuItem } from 'primeng/api';
import { InquirySettings } from 'src/app/base/models/inquiry-settings.model';

@Component({
  selector: 'grid-context-menu',
  template: `
        <kendo-popup style="min-width: 100px;" *ngIf="show" [offset]="offset">
          <button pButton pRipple type="button" icon="pi pi-pencil" label="Düzenle"  *ngIf="inquirySettings.isEdit" [disabled]='!inquirySettings.hasEdit'class="p-button-text" (click)="edit.emit(dataItem)"></button>
          <button pButton pRipple type="button" icon="pi pi-trash" label="Sil" class="p-button-text"  *ngIf="inquirySettings.isDelete" [disabled]='!inquirySettings.hasDelete' (click)="delete.emit(dataItem)"></button>

          <ng-container *ngFor="let rowAction of inquirySettings.rowActions">
            <button pButton pRipple type="button" [icon]="rowAction.icon" [label]="rowAction.label" class="p-button-text" (click)="customRowActionSelected.emit({dataItem:dataItem,rowAction:rowAction})"></button>
          </ng-container>
        </kendo-popup>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridContextMenuComponent<T> implements OnChanges, OnDestroy {

  @Input() rowActions: MenuItem[];
  @Input() inquirySettings: InquirySettings;
  @Output() edit = new EventEmitter<EditEvent>();
  @Output() delete = new EventEmitter<EditEvent>();
  @Output() customRowActionSelected = new EventEmitter<RowActionEvent<T>>();

  _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  @Input() set for(grid: GridComponent) {
    this.unsubscribe();
    grid ? this.cellClickSubscription = grid.cellClick.pipe(takeUntil(this._unsubscribeAll)).subscribe(this.onCellClick) : null;
  }

  @Input() show: boolean;
  public dataItem: T;
  public rowIndex: number;
  public offset: any;

  private cellClickSubscription: Subscription;
  private documentClickSubscription: any;

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) {
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
      this.show = false;
      this.cd.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataItem && this.dataItem['actions']) {
      // const customRowActions = this.dataItem['actions'].filter((action: MenuItem) => action.show);
      // this.rowActions = this.rowActions ? [...this.rowActions, ...customRowActions] : customRowActions;
    }

    if (changes.inquirySettings && changes.inquirySettings.currentValue) {
      this.inquirySettings.rowActions.map(x => x.icon = x.icon.toLowerCase());
    }
  }

  private onCellClick(event: CellClickEvent): void {
    if (event.type === 'contextmenu') {
      event.originalEvent.preventDefault();
      this.dataItem = event.dataItem;
      this.rowIndex = event.rowIndex;
      this.show = true;
      this.offset = { left: event.originalEvent.pageX, top: event.originalEvent.pageY };
      this.inquirySettings.rowActions = event.dataItem.RowActions ? event.dataItem.RowActions : this.inquirySettings.rowActions;
      this.cd.markForCheck();
    }
  }

  private unsubscribe(): void {
    if (this.cellClickSubscription) {
      this.cellClickSubscription.unsubscribe();
      this.cellClickSubscription = null;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.unsubscribe();
    this.unsubscribe();
    this.documentClickSubscription();
  }

}
