import {Component, OnInit} from '@angular/core';
import { MenuItems } from 'src/app/shared/menu/menu-items';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
        <!-- <a routerLink="/reportdesigner" routerLinkActive="active">First Component</a> -->
    `
})
export class AppMenuComponent implements OnInit {

    model: any[] = MenuItems.menuData;

    constructor() {}

    ngOnInit() {
        
    }
}
