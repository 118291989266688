import { Component, OnInit } from '@angular/core';
import { USER_INFO } from 'src/app/shared/authorization/user-info';
import { ROLESCONSTANTSENUM } from './model/dashboard-lists';

@Component({
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit {

    isAgencyDashboard: boolean = false;
    isCustomerDashboard: boolean = false;
    roleid: number;

    constructor() {
        this.roleid = USER_INFO.info.RoleId;
        this.isAgencyDashboard =    (this.roleid == ROLESCONSTANTSENUM.Admin_100) || 
                                    (this.roleid == ROLESCONSTANTSENUM.SuperUser_101) || 
                                    (this.roleid == ROLESCONSTANTSENUM.Agency_120) ||
                                    (this.roleid == ROLESCONSTANTSENUM.Advisor_160);
        this.isCustomerDashboard = (this.roleid == ROLESCONSTANTSENUM.CustomerManager_130);
    }

    ngOnInit() {
    }


}
