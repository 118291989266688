<div class="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center">
    <p-breadcrumb [model]="items" [home]="home" styleClass="layout-breadcrumb p-pl-4 p-py-2"></p-breadcrumb>
    <div class="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3">

        <button pButton pRipple type="button" label="Loglar"  (click)="getLogs($event)"  *ngIf="maintenanceData" class="p-button-success p-button-text p-mr-2 p-mb-2"></button>
    </div>
</div>
<p-overlayPanel #opanel2 [dismissable]="true" [style]="{width: '800px'}" [showCloseIcon]="true">
    <ng-template pTemplate>
        <table class="p-datatable-customers">
            <thead>
                <tr>
                    <th class="text-left">Oluşturan</th>
                    <th class="text-right">Açıklama</th>
                    <th class="text-right">Tarih</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of logList">
                    <td>{{ item.Owner }}</td>
                    <td>{{ item.Text}}</td>
                    <td>{{ item.Date|utcDateTimePipe: 'DATETIME'}}</td>
                </tr>
            </tbody>
        </table>
    </ng-template>
</p-overlayPanel>
