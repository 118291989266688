import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppModule } from 'src/app/app.module';
import { CustomAction } from 'src/app/base/models/cuurent-actions.model';
import { DialogComments } from 'src/app/shared/model/dialog-comment.model';

@Component({
  selector: 'app-dialog-comment',
  templateUrl: './dialog-comment.component.html',
  styleUrls: ['./dialog-comment.component.scss']
})
export class DialogCommentComponent implements OnInit {
  formd: UntypedFormGroup;
  ref = this.injector.get(DynamicDialogRef);
  config = this.injector.get(DynamicDialogConfig);
  formBuilder = this.injector.get(UntypedFormBuilder);
  submittedDialog: boolean = false;
  customaction: CustomAction;

  constructor(public injector: Injector) { }

  ngOnInit(): void {
    this.customaction = this.config.data.entity;

    this.initForm();


  }


  initForm() {
    this.formd = this.formBuilder.group({
      Descr: [],

    })

    if (this.customaction.isRequiredDescr) {
      this.formd.controls["Descr"].setValidators([Validators.minLength(10), Validators.required]);

    }
  }
  get fd() { return this.formd.controls; }

  accept() {
    this.submittedDialog = true;

    if (this.formd.invalid) {
      return;
    }
    let response = new DialogComments();
    response.descr = this.formd.controls.Descr.value;
    response.success = true;
    this.ref.close(response);

  }

}
