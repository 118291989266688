import { NgModule } from '@angular/core';
import { UtcDateTime2Pipe } from './defDate.pipe';
import { GetTotalPipe } from './get-total.pipe';
import { UtcDateTimePipe } from './utc-date-time.pipe';

import { UtcPipe } from './utc-time.pipe';

@NgModule({
  declarations: [
    UtcPipe,
    GetTotalPipe,
    UtcDateTimePipe,
    UtcDateTime2Pipe

  ],
  imports: [],
  exports: [
    UtcPipe,
    GetTotalPipe,
    UtcDateTimePipe,
    UtcDateTime2Pipe
  ],
  providers: []
})
export class PipesModule {}
