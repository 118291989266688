import { UserModel } from "./user-model";

export const USER_INFO: { info: UserModel } = {
    info: {
        Id: null,
        CmpBy: null,
        CmpOn: null,
        Email: null,
        FirstName: null,
        ForgetPasswordMail: null,
        HasRole: null,
        isAdmin: null,
        LastEntryDate: null,
        LastLoginDate: null,
        LastName: null,
        NewPassword: null,
        NewPassword2: null,
        OldId: null,
        Password: null,
        PasswordConfirmation: null,
        PasswordSalt: null,
        PasswordSalt2: null,
        PhoneNumber: null,
        RoleId: null,
        AgencyId: null,
        CustomerManagerId: null,
        CustomerEditorId: null,
        SelselectedRoles: null,
        Status: null,
        Token: null,
        TokenExpirationDate: null,
        UpdateOn: null,
        UpdatedBy: null,
        UserName: null,
        VerificationCode: null,
        XStatus: null,
        isApproved: null,
        isAuthenicated: null,
        isEditState: null,
        isLoaded: null,
        lastIpAddress: null,
        IsAdvisor: false,
        IsAdvisorEditor: false,
        IsCustomerManager: null,
        IsCustomerEditor: null,
    }
}
