import { Pipe, PipeTransform } from '@angular/core';
import { dateFieldName } from '@progress/kendo-angular-intl';
import { UtcDateType } from '../model/utc-date-type.enum';

@Pipe({
    name: 'utcDateTimePipe'
})
export class UtcDateTimePipe implements PipeTransform {



    transform(value: Date, type?: UtcDateType): any {

        if (value && typeof value === "string") {
            if (type === UtcDateType.DATETIME) {
                let dbDate = new Date(value);

                let yy = dbDate.getFullYear()
                let mm = dbDate.getMonth()
                let dd = dbDate.getDate()
                let hour = dbDate.getUTCHours();
                let minute = dbDate.getUTCMinutes();

                let shortDate = `${(dd < 10) ? 0 : ''}${dd}.${(mm < 10) ? 0 : ''}${mm + 1}.${yy} ${(hour < 10) ? 0 : ''}${hour}:${(minute < 10) ? 0 : ''}${minute}`;
                return shortDate;



                //  let dbDate=new Date(value);
                // let aaa=dbDate.getDate();
                // let day=dbDate.getUTCDay();
                // let month=dbDate.getUTCMonth();
                // let year=dbDate.getUTCFullYear();
                // let hour=dbDate.getUTCHours();
                // let minute=dbDate.getUTCMinutes();
                // return day+"."+month+"."+year+" "+hour+":"+minute;


            }

        }
        if (value && (typeof value === "object" || typeof value === "string")) {

            if (type === UtcDateType.BEGIN) {
                value.setHours(0);
                value.setMinutes(0);
                value.setSeconds(0);
                value.setMilliseconds(0);
            }
            else if (type === UtcDateType.END) {
                value.setHours(23);
                value.setMinutes(59);
                value.setSeconds(59);
                value.setMilliseconds(999);
            }
            else if (type === UtcDateType.DATETIME) {
                ///**console.log()
                let tar = new Date(value.toUTCString());
                value.setDate(value.getDate())
                value.setHours(value.getUTCHours());
                value.setMinutes(value.getUTCMinutes());
                value.setSeconds(value.getUTCSeconds());
                value.setMilliseconds(value.getUTCMilliseconds());
            }
            else {
                const dateTimeNow = new Date();
                value.setHours(dateTimeNow.getHours());
                value.setMinutes(dateTimeNow.getMinutes());
                value.setSeconds(dateTimeNow.getSeconds());
                value.setMilliseconds(dateTimeNow.getMilliseconds());
            }
        }
    }
}