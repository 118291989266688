import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Pipe({
    name: 'utcPipe'
})
export class UtcPipe implements PipeTransform {

    transform(value: Date, formControl: UntypedFormControl, isEndDte?: boolean): any {
        if (value && typeof value === "object") {

            if (isEndDte) {
                value.setHours(23);
                value.setMinutes(59);
                value.setSeconds(59);
            } else {
                value.setHours(0);
                value.setMinutes(0);
                value.setSeconds(0);
            }

            value.setHours(value.getHours() + Math.floor(Math.abs(value.getTimezoneOffset() / 60)))
            value.setMinutes(value.getMinutes() + Math.abs(value.getTimezoneOffset() % 60))

            formControl.setValue(value.toISOString());
        }
    }
}