import { NgModule } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MenusModule, ContextMenuModule, MenuModule } from '@progress/kendo-angular-menu';
import { DropDownListModule, DropDownsModule, AutoCompleteModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { GridContextMenuComponent } from '../component/grid/context-menu/grid-context-menu.component';
import { InquiryGridComponent } from '../component/grid/inquiry-grid/inquiry-grid.component';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from './primeng.module';
import { GridToolbarComponent } from '../component/grid/grid-toolbar/grid-toolbar.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  imports:[
    CommonModule,
    AutoCompleteModule,
    ButtonsModule,
    ComboBoxModule,
    ContextMenuModule,
    DateInputsModule,
    DropDownListModule,
    DropDownsModule,
    GridModule,
    ExcelModule,
    InputsModule,
    IntlModule,
    LabelModule,
    MenuModule,
    MenusModule,
    PopupModule,
    TooltipModule,
    SortableModule,
    PrimeNgModule
  ],
  declarations:[
    GridContextMenuComponent,
    GridToolbarComponent,
    InquiryGridComponent,
  ],
  exports: [
    AutoCompleteModule,
    ButtonsModule,
    ComboBoxModule,
    ContextMenuModule,
    DateInputsModule,
    DropDownListModule,
    DropDownsModule,
    GridModule,
    ExcelModule,
    InputsModule,
    IntlModule,
    LabelModule,
    MenuModule,
    MenusModule,
    PopupModule,
    SortableModule,
    TooltipModule,

    GridContextMenuComponent,
    InquiryGridComponent,
    GridToolbarComponent,
  ]
})
export class KendoModule { }
