import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditGridColumnInfo } from '../model/grid/grid-column-info';


@Injectable()
export class FormGroupService {

  constructor(private formBuilder: UntypedFormBuilder) { }

  createFormGroup(columnDefinitions: EditGridColumnInfo[], dataItem: any, _unsubscribeAll: Subject<boolean>): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    columnDefinitions.forEach(column => {

      let formControl: UntypedFormControl = new UntypedFormControl();



      //this.entity.CmpOn ? new Date(this.entity.CmpOn) : ''
      if (!column.isEditable) {
        formControl = new UntypedFormControl({ value: dataItem && dataItem[column.key] || '', disabled: true });

      } else {
        formControl = new UntypedFormControl(dataItem && dataItem[column.key] || '');

      }

      if (column.validators && column.validators.length) {
        formControl.setValidators([...column.validators]);
      }

      if (column.editor === 'date') {
        if (dataItem[column.key] !== 'undefined' && dataItem[column.key]) {
          formControl.setValue(new Date(dataItem[column.key]));
        }
      }
      else
        formControl.setValue(dataItem[column.key]);
      if (column.valueChangeCallback) {
        formControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged(), takeUntil(_unsubscribeAll)).subscribe(value => {
          column.valueChangeCallback(formGroup, dataItem, value);
        });
      }

      formGroup.addControl(column.key, formControl);
    });

    return formGroup;
  }

}
