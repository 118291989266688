<p-card class="mt-10 block inquirygrid">

        <grid-toolbar (addnew)="addNew.emit()"
        (addNewExtra)="addNewExtra.emit()"
                      (refresh)="executeSearchEmitter()"
                      (excelExport)="excelExport.emit($event)"
                      (globalFilter)="filterTable($event)"
                      [inquirySettings]="inquirySettings"
                      [grid]="grid">
        </grid-toolbar>
        <!-- <kendo-grid-checkbox-column width="40"
                media="(min-width: 450px)"></kendo-grid-checkbox-column> -->

        <kendo-grid id="kendoGrid"
                    #grid
                    [data]="gridDataResult"
                    [pageSize]="inquiryModel?.PageSize"
                    [skip]="inquiryModel?.skip"
                    [sortable]="true"
                    [sort]="inquiryModel?.sort"
                    [navigable]="true"
                    [loading]="loading"
                    [pageable]="true"
                    [sort]="inquiryModel?.sort"
                    [selectable]="true"
                    [resizable]="true"
                    (pageChange)="pageChange($event)"
                    (sortChange)="onSortChange($event)"
                    (selectionChange)="onSelectionChange($event)">


                <kendo-grid-column media="(max-width: 450px)"
                                   title="Tablo Elemanları">
                        <ng-template kendoGridCellTemplate
                                     let-dataItem>
                                <div *ngFor="let column of inquirySettings.DisplayColumns">
                                        <dl>
                                                <dt>{{column.Title}}</dt>
                                                <dd>{{dataItem[column.FieldName]}}</dd>
                                        </dl>
                                </div>
                        </ng-template>
                </kendo-grid-column>

                <ng-template kendoPagerTemplate
                             let-totalPagesf="inquiryModel.TotalRows"
                             let-currentPage="inquiryModel.CurrentPageNumber">
                        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                        <kendo-pager-info></kendo-pager-info>

                        <kendo-pager-next-buttons></kendo-pager-next-buttons>
                        <kendo-dropdownlist [data]="inquiryModel?.pageSizeOptions"
                                            (selectionChange)="pageSizeChange($event)"
                                            [(value)]="inquiryModel.PageSize">
                        </kendo-dropdownlist>
                </ng-template>
                <!-- width="'{{innerWidth>450 ? 150:50}}'" -->
                <kendo-grid-command-column [width]="this.uzunluk"
                                           class="acitions-icons">
                        <ng-template kendoGridCellTemplate
                                     let-dataItem="dataItem"
                                     let-rowIndex="rowIndex">

                                <p-menu #menu
                                        [popup]="true"
                                        [model]="contextMenuMapper(dataItem)"
                                        appendTo="body"></p-menu>
                                <button pButton
                                        *ngIf="inquirySettings.rowActions.length > 0"
                                        pRipple
                                        type="button"
                                        icon="pi pi-ellipsis-v"
                                        class="p-button-rounded p-button-text"
                                        (click)="menu.toggle($event)"></button>

                                <button pButton
                                        pRipple
                                        type="button"
                                        icon="pi pi-pencil"
                                        *ngIf="inquirySettings.isEdit"
                                        [disabled]='!inquirySettings.hasEdit'
                                        class="p-button-rounded p-button-text"
                                        (click)="edit.emit(dataItem)"></button>

                                <button pButton
                                        pRipple
                                        type="button"
                                        icon="pi pi-trash"
                                        *ngIf="inquirySettings.isDelete"
                                        [disabled]='!inquirySettings.hasDelete'
                                        class="p-button-rounded p-button-text"
                                        (click)="delete.emit(dataItem)"></button>

                        </ng-template>
                </kendo-grid-command-column>



                <kendo-grid-excel fileName="report.xlsx"></kendo-grid-excel>
                <kendo-grid-messages noRecords="Kayıt Bulunamadı..."></kendo-grid-messages>
        </kendo-grid>
</p-card>

<grid-context-menu [for]="grid"
                   [rowActions]="rowActions"
                   [inquirySettings]="inquirySettings"
                   (edit)="edit.emit($event)"
                   (delete)="delete.emit($event)"
                   (customRowActionSelected)="customRowActionSelected.emit($event)">
</grid-context-menu>
