import { CustomAction } from "./cuurent-actions.model";

export class MaintenanceSettings {
    isGotoList: boolean;
    DefaultEditMode: boolean;
    TableName: string;
    Actions: string[];
    ConstraintFields: string[];
    ReadonlyFields: string[];
    IdentifierField: string;
    CurrentActions: CustomAction[];
    rowActions: CustomAction[];
}