<div class="p-grid dashboard">

    <div class="p-col-12 p-md-12 p-lg-3">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2 dash">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="pi pi-users"></i>
                <!-- <i class="pi pi-wallet"></i> -->
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Cari Dönem Müşteri Sayısı</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge">{{ this.entity?.CariMusteriAdet }}</span>
                    <span class="overview-status p-p-1 teal-bgcolor fs-small">{{ this.entity?.CariMusteriAdet }}
                        Cari Dönem Müşteri Sayısı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2 dash">
            <div class="p-d-flex p-ai-center muted-text">
                <!-- <i class="fa fa-fw fa-sitemap"></i> -->
                <i class="pi pi-users"></i>
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Cari Dönem İşyeri Sayısı</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge">{{ this.entity?.CariIsyeriAdet }}</span>
                    <span class="overview-status p-p-1 orange-bgcolor fs-small">{{ this.entity?.CariIsyeriAdet }}
                        Cari Dönem İşyeri Sayısı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2 dash">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="pi pi-users"></i>
                <!-- <i class="pi pi-wallet"></i> -->
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Geçmiş Dönem Müşteri Sayısı</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge">{{ this.entity?.GecmisMusteriAdet }}</span>
                    <span class="overview-status p-p-1 teal-bgcolor fs-small">{{ this.entity?.GecmisMusteriAdet }}
                        Geçmiş Dönem Müşteri Sayısı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2 dash">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="fa fa-fw fa-sitemap"></i>
                <h6 class="p-m-0"
                    [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">Geçmiş Dönem İşyeri Sayısı</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge">{{ this.entity?.GecmisIsyeriAdet }}</span>
                    <span class="overview-status p-p-1 orange-bgcolor fs-small">{{ this.entity?.GecmisIsyeriAdet }}
                        Geçmiş Dönem İşyeri Sayısı</span>
                </div>
                <!-- <div class="p-d-flex p-ai-end">
                    <p-chart type="line"
                             [data]="overviewChartData"
                             [options]="overviewChartOptions"
                             responsive="true"
                             height="60px"
                             width="160px"></p-chart>
                </div> -->
            </div>
        </div>
    </div>


    <!--Grid Started-->


    <div class="p-col-12 p-md-12">
        <div class="card">
            <div class=card-header>
            </div>
            <p-table #dt1
                     [value]="this.entity?.CompanyDetails"
                     [paginator]="true"
                     [rows]="10"
                     styleClass="p-datatable-UsedPercent80Customers"
                     [globalFilterFields]="['CmpName']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText
                                   type="text"
                                   (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                   placeholder="Müşteri Ara" />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="CmpName">Müşteri Adı
                            <p-sortIcon field="CmpName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="CmpCount">İşyeri Sayısı
                            <p-sortIcon field="CmpCount"></p-sortIcon>
                        </th>
                        <th pSortableColumn="CmpcalcCount">Hesaplama Yapan İşyeri Sayısı
                            <p-sortIcon field="CmpcalcCount"></p-sortIcon>
                        </th>

                        <th style="width:4rem">View</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"
                             let-rowdata>
                    <tr>

                        <td>
                            <span class="p-column-title"></span>
                            {{rowdata.CmpName}}
                        </td>
                        <td>
                            <span class="p-column-title"></span>
                            {{rowdata.CmpCount }}
                        </td>
                        <td>
                            <span class="p-column-title"></span>
                            {{rowdata.CmpcalcCount}}
                        </td>
                        <td>
                            <span class="p-column-title"></span>
                            <button pRipple
                                    pButton
                                    (click)="openEditDialog(rowdata)"
                                    type="button"
                                    icon="pi pi-search"
                                    class="p-button-rounded p-button-text p-mb-1"
                                    [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
