
import { NgModule } from '@angular/core';
import { AppMainComponent } from './structure/app.main.component';
import { AppConfigComponent } from './structure/app.config.component';
import { AppMenuComponent } from './structure/app.menu.component';
import { AppMenuitemComponent } from './structure/app.menuitem.component';
import { AppInlineMenuComponent } from './structure/app.inlinemenu.component';
import { AppRightMenuComponent } from './structure/app.rightmenu.component';
import { AppBreadcrumbComponent } from './structure/app.breadcrumb.component';
import { AppTopBarComponent } from './structure/app.topbar.component';
import { AppFooterComponent } from './structure/app.footer.component';
import { AppNotfoundComponent } from './structure/app.notfound.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MenuService } from './structure/app.menu.service';
import { AppBreadcrumbService } from './structure/app.breadcrumb.service';
import { SharedModule } from '../shared/modules/shared.module';
import { GlobalParamService } from '../shared/services/global-param.service';
import { AppAccessdeniedComponent } from './app-acces/app.accessdenied.component';
import { AgencyDashboardComponent } from './dashboard/agency-dashboard/agency-dashboard.component';
import { CustomerDashboardComponent } from './dashboard/customer-dashboard/customer-dashboard.component';
import { DashboardAnnouncementPresenterComponent } from './dashboard/dashboard-announcement/dashboard-announcement-presenter/dashboard-announcement-presenter.component';
import { DashboardAnnouncementDetailComponent } from './dashboard/dashboard-announcement/dashboard-announcement-detail/dashboard-announcement-detail.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppRightMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppAccessdeniedComponent,
        AppNotfoundComponent,
        DashboardComponent,
        AgencyDashboardComponent,
        CustomerDashboardComponent,
        DashboardAnnouncementPresenterComponent,
        DashboardAnnouncementDetailComponent
    ],
    providers: [
        MenuService,
        AppBreadcrumbService,
        GlobalParamService
    ]
})
export class PagesModule {
    constructor() {
    }
}
