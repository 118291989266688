import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: [ './scroll-top-button.component.css' ]
})
export class ScrollTopButtonComponent {
    showScroll: boolean;
    showScrollHeight = 300;
    hideScrollHeight = 200;

    constructor() { }

    @HostListener('window:scroll', [])
    onWindowScroll() 
    {
      if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) 
      {
          this.showScroll = true;
      } 
      else if ( this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) 
      { 
        this.showScroll = false; 
      }
    }

    ngOnInit(): void 
    {
      
    }

    scrollToTop() 
    { 
      (function smoothscroll() 
      { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
        if (currentScroll > 0) 
        {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 5));
        }
      })();
    }
}