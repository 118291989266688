import { Injectable, EventEmitter } from "@angular/core";
import { Message, MessageService } from "primeng/api";


@Injectable()

export class AlertService {
  public progressBarUIEvent: EventEmitter<any>;

  constructor(private alertservice: MessageService) {


    setTimeout(() => {
      this.progressBarUIEvent = new EventEmitter(false);
    });

  }

  msgs: Message[] = [];



  public startProgressBar() {
    setTimeout(() => {
      this.progressBarUIEvent.emit(true);
    });
  }

  public stopProgressBar() {
    setTimeout(() => {
      this.progressBarUIEvent.emit(false);
    });
  }

  public SuccessMessage(message: string) {

    this.alertservice.add({ key: 'tst', severity: 'success', summary: 'İşlem Başarılı', detail: message });

    // this.msgs = [];
    // this.msgs.push({ severity: 'succes', summary: message, detail: 'Tha Ok' });
    this.stopProgressBar();
  }

  public ErrorMessage(message: string) {

    this.alertservice.add({ key: 'tst', severity: 'error', summary: 'Hata!', detail: message });
    // this.msgs = [];
    // this.msgs.push({ severity: 'error', summary: message, detail: 'Hata !!!' });
    this.stopProgressBar();
  }

  public ShowWarningMessage(message: string) {

    this.alertservice.add({ key: 'tst', severity: 'warn', summary: 'Bilgi', detail: message });
    //this.msgs.push({ severity: 'error', summary: message, detail: 'Uyarı !!!' });
    this.stopProgressBar();
  }
}
