import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActionResponse } from 'src/app/base/models/base-response.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PortalGlobalService } from 'src/app/shared/services/portal-global.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private alertService: AlertService, private portalGlobalService: PortalGlobalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map(event => {
      if (event instanceof HttpResponse) {
        event.body && event.body.hasOwnProperty('ReturnStatus') && !(event.body as ActionResponse<any>).ReturnStatus ? this.alertService.ErrorMessage((event.body as ActionResponse<any>).ReturnMessage[0]) : null;
      }
      return event;
    }), catchError(err => {
      this.portalGlobalService.blockUI.stop();
      let error;

      if (err.status === 400) {
        error = 'Gönderilen Model Uygun Değildir.';
        this.alertService.ErrorMessage(error);
      } else if (err.status === 401) {
        localStorage.removeItem('token');
        this.router.navigate([`/authentication/login`]);
      }
      else if (err.status === 422) {
        this.alertService.ErrorMessage("Bu İşlemi Yapmaya Yetkiniz Bulunmamaktadır");

      }
      else if (err.status === 425) {
        this.router.navigate([`/access`]);

        this.alertService.ErrorMessage("Bu Sayfayı Görüntülemeye Yetkiniz Bulunmamaktadır");

      }


      return throwError(error);
    }));
  }
}
