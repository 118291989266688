

export class AppMenuItem {
    label?: string;
    icon?: string;
    routerLink?: string;
    isAction?:boolean;
    selectable?: boolean;
    code?: string;
    items?:AppMenuItem[];
    actionTotal?:number;
}

export class MenuData
{
    menuData:AppMenuItem[]
}

export let MenuItems : MenuData = {menuData:[]};