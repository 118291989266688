import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Subscription } from "rxjs";
import { ResponseModel } from "src/app/base/models/response.model";
import { selectmodel } from "../select-model";


export enum FileQueueStatus {
  Pending,
  Success,
  Error,
  Progress
}

export class FileQueueObject {
  public file: string | ArrayBuffer;
  public size: number;
  public id: number;
  public tempId:number;
  public tableName: string;
  public owner: string;
  public fileName: string;
  public recid: string;
  public categotyList: selectmodel[];
  public category: string;
  public ftitle: string;
  public categoryName: string;
  public companyId: string;
  public data: any;
  public status: FileQueueStatus = FileQueueStatus.Pending;
  public progress: number = 0;
  public request: Subscription = null;
  public response: HttpResponse<any> | HttpErrorResponse = null;


  constructor(file: any, id?: number, tablename?: string, ctgList?: selectmodel[], selectedCategory?: selectmodel) {
    this.id = id;
    // this.tempId=Date.now() + Math.floor(Math.random() * 0x10000);
    this.file = file;
    this.tableName = tablename;
    this.categotyList = ctgList;
    this.category = selectedCategory?.value === undefined ? '' : selectedCategory.value;
    this.categoryName = selectedCategory?.label === undefined ? '' : selectedCategory.label;
  }

  // actions
  public upload = () => { /* set in service */ };
  public cancel = () => { /* set in service */ };
  public remove = () => { alert("Remove :" + this.recid) };

  // statuses
  public isPending = () => this.status === FileQueueStatus.Pending;
  public isSuccess = () => this.status === FileQueueStatus.Success;
  public isError = () => this.status === FileQueueStatus.Error;
  public inProgress = () => this.status === FileQueueStatus.Progress;
  public isUploadable = () => this.status === FileQueueStatus.Pending || this.status === FileQueueStatus.Error;

}
export class FileResponseModel extends ResponseModel {
  public Entity: Array<FileQueueObject>;

}

export class FileSearchModel {
  public fileType: string;
  public recid: string;
  public tableName: string;

}