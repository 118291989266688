import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subject } from "rxjs";
import { EntityBaseInfo } from "../model/entity-file-info.model";

@Injectable()
export class PortalGlobalService {
    @BlockUI() blockUI: NgBlockUI;
    public maintenanceModel$: Subject<EntityBaseInfo> = new Subject<EntityBaseInfo>();

    constructor() {}

    addMaintenanceFileInfo(data: EntityBaseInfo) {
        this.maintenanceModel$.next(data);
    }
}