import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.Interceptor';
import { ErrorInterceptor } from './core/interceptors/error.Interceptor';
import { AuthService } from './shared/services/auth.service';
import { throwError } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PortalGlobalService } from './shared/services/portal-global.service';
import { AppApiService } from './shared/services/app-api.service';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUITemplateComponent } from './shared/component/block-ui/block-ui-template.component';
import { ConfirmService } from './shared/services/confirm.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { Router } from '@angular/router';
import { USER_INFO } from './shared/authorization/user-info';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import "@progress/kendo-angular-intl/locales/tr/all";
import localeTr from '@angular/common/locales/tr';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';

registerLocaleData(localeTr);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        PagesModule,
        ToastModule,
        SweetAlert2Module.forRoot(),
        ConfirmDialogModule,
        BlockUIModule.forRoot({
            template: BlockUITemplateComponent
        }),
        TooltipModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        PortalGlobalService,
        AppApiService,
        AuthService,
        ConfirmService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: startSession, deps: [AuthService, Router], multi: true },
        { provide: LOCALE_ID, useValue: "tr-TR" },
        { provide: LocationStrategy, useClass:HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    static injector: Injector;
    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}


export function startSession(authService: AuthService, route: Router) {
    if (localStorage.getItem('token')) {
        authService.startSession();
    }

    return () => {
        return new Promise(async (resolve, reject) => {
            USER_INFO.info.RoleId ? await authService.getMenuForUser().catch(err => { return throwError(err); }) : null;
            resolve(true);
        })
    }
}