import { AfterViewInit, Component, ContentChildren, DebugElement, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ColumnComponent, GridComponent, GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { FilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { InquirySettings } from 'src/app/base/models/inquiry-settings.model';
import { RowActionEvent } from 'src/app/shared/model/grid/row-action.model';
import { process } from "@progress/kendo-data-query";
import { BaseInquiryRequest } from 'src/app/base/models/base-inquiry-request.model';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-inquiry-grid',
  templateUrl: './inquiry-grid.component.html',
  styleUrls: ['./inquiry-grid.component.scss']
})
export class InquiryGridComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(GridComponent) grid: GridComponent;
  @ContentChildren(ColumnComponent) columns;
  @Input() gridData: T[];
  @Input() inquirySettings: InquirySettings;
  @Input() inquiryModel: BaseInquiryRequest;
  @Input() loading: boolean;
  @Input() rowActions: MenuItem[] = [];
  @Input() set gridBinder(grid: GridComponent) {
    this.grid = grid;
  }
  @Output() executeSearch = new EventEmitter<any>();
  @Output() edit = new EventEmitter<T>();
  @Output() delete = new EventEmitter<T>();
  @Output() addNew = new EventEmitter<T>();
  @Output() customRowActionSelected = new EventEmitter<RowActionEvent<T>>();
  @Output() excelExport = new EventEmitter<GridComponent>();
  @Output() gridSelectionChange = new EventEmitter<T[]>();
  gridDataResult: GridDataResult;
  selectedRows: T[] = [];

  public innerWidth: any;
  public uzunluk: any=50;
  constructor() { }

  @Output() addNewExtra = new EventEmitter<void>();

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    if(this.innerWidth>450)
    this.uzunluk=150;
    

  }

  ngAfterViewInit(): void {
    this.grid.columns.reset([this.grid.columns.toArray()[0], this.columns.toArray(), this.grid.columns.toArray()[this.grid.columns.toArray().length - 1]]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridData && changes.gridData.currentValue) {
      this.gridDataResult = { data: orderBy(this.gridData, this.inquiryModel.sort), total: this.inquiryModel.TotalRows };
    }
  }

  contextMenuMapper(item: T): MenuItem[] {
    let menuItems: MenuItem[] = [];
    this.inquirySettings.rowActions.map(rowAction => {
        menuItems.push({
          label: rowAction.label,
          icon: rowAction.icon.toLowerCase(),
          id: rowAction.actionName,
          command: (event) => { this.customRowActionSelected.emit({ dataItem: item, rowAction: event.item }); }
        })
    });
    return [{ items: menuItems }];
  }

  executeSearchEmitter() {
    this.selectedRows = [];
    this.gridSelectionChange.emit(this.selectedRows);
    this.executeSearch.emit();
  }

  filterTable(inputValue) {

    const filters: FilterDescriptor[] = [];

    this.grid.columns.toArray().forEach((item: ColumnComponent) => {
      item.field ? filters.push({
        field: item.field,
        operator: "contains",
        value: inputValue,
      }) : null
    });

    this.gridDataResult.data = process(this.gridData, {
      filter: {
        logic: "or",
        filters: filters
      },
    }).data;
  }


  public onSortChange(sort: SortDescriptor[]): void {
    this.inquiryModel.sort = sort;
    this.executeSearchEmitter();
  }


  public pageChange(event: PageChangeEvent): void {
    this.inquiryModel.skip = event.skip;
    this.inquiryModel.CurrentPageNumber = (event.skip / event.take) + 1;
    this.executeSearchEmitter();
  }


  public pageSizeChange(value: number): void {
    this.inquiryModel.PageSize = value;
    this.executeSearchEmitter();
  }

  onSelectionChange(event: SelectionEvent) {
    if (event.selectedRows.length > 0) {
      this.selectedRows.push(event.selectedRows[0].dataItem);
    }

    if (event.deselectedRows.length > 0) {
      this.selectedRows.splice(this.selectedRows.findIndex(x => x[this.inquirySettings.IdentifierField] === event.deselectedRows[0].dataItem[this.inquirySettings.IdentifierField]), 1);
    }

    this.gridSelectionChange.emit(this.selectedRows);
  }
}
