export class ResponseModel {

    public ReturnStatus: boolean;
    public ReturnMessage: string[];
    public Entity: any;

}
export interface ApiResponse<T> extends ResponseModel {

}
export class GeneralSearchModel {
    TextValue: string;
    NumericValue: number
}
