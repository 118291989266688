import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime, find } from 'rxjs/operators';
import { EditGridColumnInfo, EditGridInfo } from 'src/app/shared/model/grid/grid-column-info';
import { FormGroupService } from 'src/app/shared/services/form-group.service';

@Component({
  selector: 'app-edit-grid',
  templateUrl: './edit-grid.component.html',
  styleUrls: ['./edit-grid.component.scss']
})
export class EditGridComponent<T> implements OnInit, OnDestroy, OnChanges {
  @ViewChild(GridComponent) grid: GridComponent;
  @Input() data: T[];
  @Input() editGridInfo: EditGridInfo;
  @Input() disabled: boolean;
  @Output() dropdownFilterChange: EventEmitter<{ srcParam: string, columnInfo: EditGridColumnInfo, dataItem: T }> = new EventEmitter();
  @Output() editItem: EventEmitter<{ item: T, data: T[] }> = new EventEmitter();
  @Output() addNewItem: EventEmitter<{ item: T, data: T[] }> = new EventEmitter();
  @Output() deletItem: EventEmitter<{ item: T, data: T[] }> = new EventEmitter();
  formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  dropdownTextChanged = new Subject<{ srcParam: string, columnInfo: EditGridColumnInfo, dataItem: T }>();
  _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  showDropDowns = true;
  

  constructor(private formService: FormGroupService, private confirmationService: ConfirmationService) { }


  ngOnInit(): void {
    this.dropdownTextChanged.pipe(debounceTime(500)).subscribe(res => {
      if (res.columnInfo.dropDownInfo.filterInFront) {
        res.columnInfo.dropDownInfo['optionsStorage'] ? null : res.columnInfo.dropDownInfo['optionsStorage'] = JSON.parse(JSON.stringify(res.columnInfo.dropDownInfo.options));
        res.columnInfo.dropDownInfo.options = JSON.parse(JSON.stringify(res.columnInfo.dropDownInfo['optionsStorage'].filter(x => x[res.columnInfo.dropDownInfo.textField].toLowerCase().includes(res.srcParam))))
      } else {
        this.dropdownFilterChange.emit(res);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && changes.disabled.currentValue) {
      this.grid ? this.closeEditor(this.grid) : null;
    }
  }

  // #region Column
  setSelctedDataForDropdowns(dataItem: any) {
    this.editGridInfo.columnsInfo.filter(column => column.dropDownInfo).forEach(column => {
      if (dataItem[column.key] && column.dropDownInfo.options.findIndex(x => x[column.dropDownInfo.valueField] === dataItem[column.key]) === -1) {
        column.dropDownInfo.options.push({ [column.dropDownInfo.valueField]: dataItem[column.key], [column.dropDownInfo.textField]: dataItem[column.dropDownInfo.selectedItemFieldName] });
      }
    });
  }

  onDropDownFilterChange(srcParam: string, columnInfo: EditGridColumnInfo, dataItem: T) {
    this.dropdownTextChanged.next({ srcParam: srcParam, columnInfo: columnInfo, dataItem: dataItem });
  }

  dropDownSelectionChanged(event, dataItem: T, columnInfo: EditGridColumnInfo, rowIndex: number) {
    this.formGroup.controls[columnInfo.dropDownInfo.selectedItemFieldName] ? this.formGroup.controls[columnInfo.dropDownInfo.selectedItemFieldName].setValue(event[columnInfo.dropDownInfo.textField]) :
      this.formGroup.addControl(columnInfo.dropDownInfo.selectedItemFieldName, new UntypedFormControl(event[columnInfo.dropDownInfo.textField]))
    dataItem[columnInfo.dropDownInfo.selectedItemFieldName] = event[columnInfo.dropDownInfo.textField];
    this.showDropDowns = false;
    setTimeout(() => {
      this.showDropDowns = true;
    });
  }

  //#endregion Columnm

  // #region Row
  public addHandler({ sender }) {
    this.closeEditor(sender);
    this.formGroup = this.formService.createFormGroup(this.editGridInfo.columnsInfo, {} as T, this._unsubscribeAll);
    sender.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, formGroup,dataItem }) {
    this.closeEditor(sender);
    this.formGroup = this.formService.createFormGroup(this.editGridInfo.columnsInfo, dataItem, this._unsubscribeAll);
    this.editedRowIndex = rowIndex;
    this.setSelctedDataForDropdowns(dataItem);
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }) {

    const item: T = { ...dataItem, ...(formGroup as UntypedFormGroup).getRawValue() };
    item[this.editGridInfo.rowIdentifier] === "" || item[this.editGridInfo.rowIdentifier] === 0 ? delete item[this.editGridInfo.rowIdentifier] : null;
    if (isNew) {
      this.data.push(item);
      this.addNewItem.emit({ item: item, data: this.data });
    } else {
      this.data[rowIndex] = item;
      this.editItem.emit({ item: item, data: this.data });
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem, rowIndex }) {
    this.confirmationService.confirm({
      key: "reomveDialog",
      header: `Remove`,
      message: "Are You Sure To Remove This Item?",
      accept: () => {
        this.data.splice(rowIndex, 1);
        this.deletItem.emit({ item: dataItem, data: this.data });
      },
      reject: () => {
      },
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //#endregion Row


  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.unsubscribe();
  }
}
