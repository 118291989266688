import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { AppModule } from "src/app/app.module";
import { ActionResponse } from "src/app/base/models/base-response.model";
import { environment } from "src/environments/environment";
import { LogEntity } from "../modules/log.model";

@Injectable()
export class AppApiService {

    constructor(private http: HttpClient) { }

    getLogs(recid: string, tableName: string): Observable<ActionResponse<LogEntity>> {
        const url = `${environment.apiUrl}Log/GetLogs/${recid}/${tableName}`;
        return this.http.get<ActionResponse<LogEntity>>(url);
    }

}