import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { USER_INFO } from 'src/app/shared/authorization/user-info';

@Injectable({ providedIn: 'root' })
export class DashboardGuard implements CanActivate {
  constructor() { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     if (state.url === '/dashboard') {
      return true;
     }else {
      return false;
    }

    // if (state.url === '/dashboard' && USER_INFO.info.DealerShipId !== 0) {
    //   return true;
    // } else if (state.url === '/admin-dashboard' && USER_INFO.info.DealerShipId === 0) {
    //   return true;
    // }else {
    //   return false;
    // }
  }
}
