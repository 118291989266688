<form name="formd" [formGroup]="formd" class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
        <div class="card">
            <div class="p-fluid">

                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <span class="p-float-label">
                            <textarea id="description" formControlName="Descr" pInputTextarea rows="5"
                                cols="15"></textarea>
                            <label for="description">Açıklama</label>
                        </span>
                        
                        <div *ngIf="submittedDialog && fd.Descr.errors" class="invalid-feedback">
                            <p-message *ngIf="fd.Descr.errors.required" severity="error"
                                text="Açıklama alanı boş olamaz"></p-message>
                                <p-message *ngIf="fd.Descr.errors.minlength" severity="error"
                                text="En Az 10 karakter giriniz"></p-message>
                            
                        </div>
                    </div>
                </div>
                <div class="p-col-12">

                    <button pButton pRipple label="{{customaction.label}}" icon="pi pi-check"   (click)="accept()" class="p-button-text"></button>
                </div>
            </div>
        </div>
    </div>
</form>