import { Component, EventEmitter, OnInit, Output, ViewChild, Input, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { Message } from 'primeng/api';
import { FileService } from 'src/app/shared/services/file-service';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { ModuleStateService } from 'src/app/shared/services/module-state.service';
import { selectmodel } from 'src/app/shared/model/select-model';
import { FileQueueObject, FileResponseModel, FileSearchModel } from 'src/app/shared/model/file/file-model';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.scss'],
})

export class FileUploadComponent implements OnInit {
    @Input() tableName: string;
    @Input() recid: string;
    @Input() isAdFile: true;
    @Input() isEditState: boolean;
    @Input() categoryList: selectmodel[];
    @Output() onCompleteItem = new EventEmitter();
    @Output() fileInserted = new EventEmitter<FileQueueObject>();
    @ViewChild('fileInput') fileInput;
    public postUrl: string = environment.apiUrl + "File/Upload";
    queue: Observable<FileQueueObject[]>;
    fileList: FileQueueObject[] = [];
    alertService = this.injector.get(AlertService);
    display: boolean;
    msgs: Message[] = [];
    selectedCategory: selectmodel;

    constructor(
        private fileService: FileService,
        public injector: Injector,
        private myConfirmationService: ConfirmService,
        public app: AppComponent,
        private moduleStateService: ModuleStateService
    ) { }


    ngOnInit() {
        this.getMyListData();
        this.isEditState = this.isEditState;
        if (this.isAdFile === undefined) {
            this.isAdFile = true;
        }
    }

    completeItem = (item: FileQueueObject, response: any) => {
        this.onCompleteItem.emit({ item, response });
    }

    public getMyListData() {

        var searchModel = new FileSearchModel();
        searchModel.tableName = this.tableName;
        searchModel.recid = this.recid;

        this.fileService.getMyFiles(searchModel)
            .subscribe((response: FileResponseModel) => {
                this.myDataListSuccess(response);
            },
                response => this.myDataListFailed(response)
            );
    }

    private myDataListFailed(error: HttpErrorResponse) {

        const errorResponse: FileResponseModel = error.error;

        if (error.status > 0) {
            this.alertService.ErrorMessage(errorResponse.ReturnMessage[0]);
        } else {
            this.alertService.ErrorMessage(error.message);
        }
    }


    public download(item: FileQueueObject) {
        this.fileService.downloadFile(item);
    }

    public remove(item: FileQueueObject) {
        this.myConfirmationService.confirmByMessage("Dosya Kaldırılacak, Emin misiniz?").then(res => {
            if (res) {
                this.deleteFile(item);
            }
        })

    }

    public deleteFile(item: FileQueueObject) {
        this.fileService.removeMyFiles(item)
            .subscribe((response: FileResponseModel) => {
                this.removeItemSuccess(response);
                this.alertService.SuccessMessage("Dosya Kaldırıldı");
            });
    }

    private myDataListSuccess(response: FileResponseModel) {
        this.fileList = response.Entity;
    }

    private removeItemSuccess(response: FileResponseModel) {
        this.fileList = response.Entity;
        this.getMyListData();
    }

    public async Upload(event: Event) {

        let file: FileQueueObject = new FileQueueObject(null, 0, this.tableName, this.categoryList, this.selectedCategory);
        let uploadedFile = (<HTMLInputElement>event.target).files[0];
        file.fileName = uploadedFile.name;
        file.size = uploadedFile.size;

        
        let dataURL = await this.readFileAsDataURL(uploadedFile)
        file.file = dataURL as ArrayBuffer;

        this.msgs = [];
        if (this.categoryList) {
            if (!this.selectedCategory) {
                this.msgs.push({ severity: 'error', summary: "Lütfen Kategori Seçiniz", detail: '' });
                this.fileInput.nativeElement.value = ""
                return;
            }
        }

        this.display = false;
        this.fileList.push(file);

        if (this.recid) {
            file.recid = this.recid;
            this.fileService.uploadFile([file]).subscribe(response => {
                if (response.ReturnStatus) {
                    this.uploadSuccess(response);
                }
            })
        } else {
            this.fileInserted.emit(file);
        }
    }

    private uploadSuccess(response: FileResponseModel) {
        this.fileList = response.Entity;
        this.alertService.SuccessMessage("Dosya Eklendi");
        this.selectedCategory = undefined;
        this.msgs = null;
    }


    async readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return result_base64;
    }
}
