import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseEntity } from 'src/app/base/models/base-entity.model';
import { ActionData, CustomAction } from 'src/app/base/models/cuurent-actions.model';
import { ManipulationMode } from 'src/app/shared/enum/manipulation-mode.enum';
import { DialogComments } from 'src/app/shared/model/dialog-comment.model';
import { MaintenanceActionButtonConfig } from 'src/app/shared/model/maintenance-action-button-config.model';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { DialogCommentComponent } from '../dialog-comment/dialog-comment.component';

@Component({
  selector: 'app-maintanance-action-button',
  templateUrl: './maintanance-action-button.component.html',
  styleUrls: ['./maintanance-action-button.component.scss']
})
export class MaintananceActionButtonComponent<T extends BaseEntity> implements OnInit, OnChanges, AfterViewInit {

  dialogService = this.injector.get(DialogService);
  actDialog: boolean;
  actBtnDescr: string;
  activeAction: CustomAction = null;
  sbmtFrm: boolean;
  formd: UntypedFormGroup;

  formBuilder = this.injector.get(UntypedFormBuilder);

  @Input() maintenanceActionButtonConfig: MaintenanceActionButtonConfig;
  @Input() entity: T;



  @Output() save: EventEmitter<null> = new EventEmitter();
  @Output() goBack: EventEmitter<null> = new EventEmitter();
  @Output() cancelEntity: EventEmitter<undefined> = new EventEmitter();
  @Output() cancelForm: EventEmitter<undefined> = new EventEmitter();
  @Output() actionHandler: EventEmitter<ActionData> = new EventEmitter();
  @Output() editModeHandler: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('messageContent') messageContent: ElementRef;

  editAccess: boolean;
  cancelAccess: boolean;
  manipulationMode = ManipulationMode;
  hasMessageContent: boolean = true;
  constructor(private confirmService: ConfirmService, public injector: Injector) { }


  ngOnInit(): void {

    this.formd = this.formBuilder.group({
      Descr: [],

    })

    this.editAccess = this.maintenanceActionButtonConfig.maintenanceSettings.Actions.includes('Save');
    this.cancelAccess = this.maintenanceActionButtonConfig.maintenanceSettings.Actions.includes('Cancel');
    if (this.maintenanceActionButtonConfig.maintenanceSettings.DefaultEditMode && this.editAccess) {
      this.editClicked();
    }
    this.setReadOnlyFields();

    if (this.maintenanceActionButtonConfig.mode === ManipulationMode.create) {
      this.maintenanceActionButtonConfig.canManageForm = true;
      this.maintenanceActionButtonConfig.form.enable();
      this.setReadOnlyFields();
    } else {
      this.maintenanceActionButtonConfig.maintenanceSettings.DefaultEditMode ? null : this.maintenanceActionButtonConfig.form.disable();
    }
    this.editModeHandler.emit(!this.maintenanceActionButtonConfig.form.disabled);

  }

  ngAfterViewInit() {
    if (this.messageContent.nativeElement.children.length === 1) {
      this.hasMessageContent = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void { }

  actionHandlerClick(data: CustomAction) {

    this.activeAction = data;

    if (data.isOpenDialog) {
      this.actDialog = true;
      this.openCommentDialog(data);
    }
    else if (data.isConfirm) {
      this.confirmService.confirmByMessage(data.label + " İşlemi Yapılacak Emin Misiniz?").then(res => {
        if (res) {
          this.execHandler(data.actionName);
        }
      })
    }
    else {

      this.execHandler(data.actionName);

    }
  }

  execHandler(actionName: string) {
    this.actionHandler.emit({ actionName: actionName, formData: '' });

  }
  editClicked() {
    this.maintenanceActionButtonConfig.canManageForm = true;
    this.maintenanceActionButtonConfig.form.enable();
    this.setReadOnlyFields();
    this.setConstraintFields();
    this.editModeHandler.emit(true);

  }

  setReadOnlyFields() {
    if (this.maintenanceActionButtonConfig.maintenanceSettings.ReadonlyFields) {
      for (let item of this.maintenanceActionButtonConfig.maintenanceSettings.ReadonlyFields) {
        this.maintenanceActionButtonConfig.form.controls[item] ? this.maintenanceActionButtonConfig.form.controls[item].disable() : null;
      }
    }
  }

  setConstraintFields() {
    if (this.maintenanceActionButtonConfig.maintenanceSettings.ConstraintFields) {
      for (let item of this.maintenanceActionButtonConfig.maintenanceSettings.ConstraintFields) {
        this.maintenanceActionButtonConfig.form.controls[item] ? this.maintenanceActionButtonConfig.form.controls[item].disable() : null;
      }
    }
  }

  onCancelEntity() {
    this.confirmService.confirmDelete().then(res => {
      if (res) {
        this.cancelEntity.emit();
      }
    })
  }

  openCommentDialog(action: CustomAction) {

    this.actBtnDescr = action.label;

    if (action.isRequiredDescr) {
      this.formd.controls["Descr"].setValidators([Validators.minLength(10), Validators.required]);
    }
    this.formd.controls.Descr.setValue('');

  }
  execHandlerWithData() {
    this.actDialog = false;
    this.actionHandler.emit({ actionName: this.activeAction.actionName, formData: this.formd.controls.Descr });

  }
  giveUpForm() {
    this.editModeHandler.emit(false);
    this.cancelForm.emit();
  }

  clear() {
    this.maintenanceActionButtonConfig.form.reset();
  }

  onSave() {
    this.save.emit();
  }

  hideDialog() {
    this.sbmtFrm = false;
    this.actDialog = false;
  }

}


