import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AppComponent } from 'src/app/app.component';
import { DashboardAnnouncementEntity } from '../../model/dashboard.model';
import { DashboardService } from '../../service/dashboard.service';
import { DashboardAnnouncementDetailComponent } from '../dashboard-announcement-detail/dashboard-announcement-detail.component';

@Component({
    selector: 'app-dashboard-announcement-presenter',
    templateUrl: './dashboard-announcement-presenter.component.html',
    styleUrls: ['./dashboard-announcement-presenter.component.scss'],
    providers: [DashboardService],

})
export class DashboardAnnouncementPresenterComponent implements OnInit {

    entity:DashboardAnnouncementEntity;
  
    constructor(public app: AppComponent, 
                private service: DashboardService,
                public dialogService: DialogService) { }

    ngOnInit(): void {

        this.entity=new DashboardAnnouncementEntity();
        this.getAnnouncement();
    }

    async getAnnouncement() {
        await this.service.GetAnnouncement().then(res => {
            if (res.ReturnStatus) {
                this.entity = res.Entity;
            }
        })
    }
    public goToAnnouncementDetail(item){

        // this.dialog.open(DashboardAnnouncementDetailComponent, {
        //   data:item,
        // });
        const ref = this.dialogService.open(DashboardAnnouncementDetailComponent, {
            // header: "Teşvik Alan Personeller",
            // width: 'fit-content',
            width: '40%',

            data: item
          });
      
      }
}

