<div class="p-fluid p-formgrid p-grid ng-untouched ng-pristine">

<div class="p-col-12 p-md-6 mt-10">
  <div class="p-grid dashboard"> &nbsp;&nbsp;
    <div class="p-col-12 p-md-12">
      <div class="card">
        <div class="card-header">
          <h5>Haberler</h5>
        </div>
  
        <div *ngFor="let item of this.entity.Haberler"
                (click)="goToAnnouncementDetail(item)"
                [header]="item.Baslik.length>50?item.Baslik.substr(0,50)+'...':item.Baslik"
                [style]="{width: '100%'}">
  
          <div>
            {{item.Detay.length>200?item.Detay.substr(0,120)+'...':item.Detay}}
          </div>
          <p-footer>
            {{item.YayinTarihi|utcDateTimePipe: 'DATETIME' }}
          </p-footer>
        </div>
      </div>
    </div>
     
  </div>
</div>


<div class=" p-col-12 p-md-6 mt-10">
  <div class="p-grid dashboard"> &nbsp;&nbsp;
<div class="p-col-12 p-md-12">
  <div class="card">
    <div class="card-header">
      <h5>Duyurular</h5>
    </div>
    <div *ngFor="let item of this.entity.Duyurular"
            (click)="goToAnnouncementDetail(item)"
            [header]="item.Baslik.length>50?item.Baslik.substr(0,50)+'...':item.Baslik"
            [style]="{width: '100%'}">

      <div>
        {{item.Detay.length>200?item.Detay.substr(0,120)+'...':item.Detay}}
      </div>
      <p-footer>
        {{item.YayinTarihi|utcDateTimePipe: 'DATETIME'}}
      </p-footer>
    </div>
  </div>
</div>
</div>
</div>
</div>