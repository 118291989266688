import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUserToken = localStorage.getItem('token');
    if (currentUserToken) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${currentUserToken}`,
          'Content-Type': 'application/json'
        }
      });
  }
    return next.handle(request);
  }
}
