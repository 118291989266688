import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { PortalGlobalService } from 'src/app/shared/services/portal-global.service';
import { EntityBaseInfo } from 'src/app/shared/model/entity-file-info.model';
import { LogEntity, LogInquiry } from 'src/app/shared/modules/log.model';
import { AppModule } from 'src/app/app.module';
import { AppApiService } from 'src/app/shared/services/app-api.service';
import { HttpParams } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ActionResponse } from 'src/app/base/models/base-response.model';
import { USER_INFO } from 'src/app/shared/authorization/user-info';

@Component({
    selector: 'app-breadcrumb',
    styleUrls: ['./app.breadcrumb.component.scss'],

    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {
    @ViewChild('opanel2') opanel: OverlayPanel;

    subscription: Subscription;
    @Input() isMaintenance: boolean;
    public logList: LogEntity[];
    items: MenuItem[];
    home: MenuItem;
    maintenanceData: EntityBaseInfo;


    constructor(public breadcrumbService: AppBreadcrumbService, public portalGlobalService: PortalGlobalService, private appApiService: AppApiService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });

        this.subscription = this.portalGlobalService.maintenanceModel$.subscribe(response => {

            this.maintenanceData = response;
        });

        this.home = { icon: 'pi pi-home', routerLink: '/dashboard' };
    }

    getLogs(event: any) {

        this.opanel.show(event);

        this.appApiService.getLogs(this.maintenanceData.Id, this.maintenanceData.tableName).subscribe(
            (response: ActionResponse<LogEntity>) => {
                this.logList = response.EntityList;

            }
            // response => this.getCompanyUserListFailed(response)
        );


    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
