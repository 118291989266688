import { BaseEntity } from "src/app/base/models/base-entity.model";
import { AnnouncementEntity } from "src/app/modules/announcement-management/announcement/model/announcement.model";

//******************* Customer *********************** */
export class CustomerDashboardEntity extends BaseEntity {
  public TesvikAlanPers: number;
  public SonrakiDonemPers: number;
  public ToplamTesvikTutar: number;
  public persGraphData: GraphData;
  public tesvikGraphData: GraphData;
}

export class GraphData {
  public labels: string[];
  public datasets: Array<DatasetModel>
}

export class DatasetModel {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  backgroundColor: string;
  tension: number = 4;
}

//******************* Agency *********************** */
export class AgencyDashboardEntity extends BaseEntity {
  public CariMusteriAdet: number;
  public CariIsyeriAdet: number;
  public GecmisMusteriAdet: number;
  public GecmisIsyeriAdet: number;

  public CompanyDetails: Array<CompanyDetail>;
}
export class CompanyDetail {

  public CmpName: string;
  public CmpCount: number;
  public CmpcalcCount: number;
}

//******************* Dashboard Announcement *********************** */

export class DashboardAnnouncementEntity extends BaseEntity {
  Haberler: AnnouncementEntity[];
  Duyurular: AnnouncementEntity[];

}
