import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/internal/Observable';
import { InquirySettings } from 'src/app/base/models/inquiry-settings.model';

@Component({
  selector: 'grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss']
})
export class GridToolbarComponent implements OnInit {

  @Input() grid: GridComponent;
  @Input() inquirySettings: InquirySettings;
  @Output() excelExport = new EventEmitter<GridComponent>();
  @Output() refresh = new EventEmitter<void>();
  @Output() addnew = new EventEmitter<void>();
  @Output() globalFilter = new EventEmitter<string>();
  @Output() addNewExtra = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {}

}
