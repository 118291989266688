<div class="layout-topbar p-shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo"
           routerLink="/{{urlBase}}">
            <img id="app-logo"
                 src="assets/layout/images/vergi-global1.png"
                 alt="ultima-layout"
                 style="height: 5.25rem">
        </a>

<!-- 
        <a class="layout-menu-button p-shadow-6"
           (click)="appMain.onMenuButtonClick($event)"
           pRipple>
            <i class="pi pi-chevron-right"></i>
        </a> -->

        <a class="layout-topbar-mobile-button"
           (click)="appMain.onTopbarMobileButtonClick($event)"
           pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>

        </a>

    </div>

    <div class="layout-topbar-right"
         [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">

            <p-menubar [model]="items" ></p-menubar>

            <!-- <p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu> -->
            <!-- <span class="p-d-flex p-flex-column"
                  style="float:right;">
                <span class="p-text-bold">{{username}}</span>
            </span> -->
        </div>
        <!-- <div class="layout-topbar-actions-left">
        </div> -->

        <div class="layout-topbar-actions-right">

            <ul class="layout-topbar-items">
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                       (click)="appMain.onTopbarItemClick($event, 'profile')"
                       pRipple>

                        <p-avatar icon="pi pi-user"
                                  [style]="{'color': '#ffffff'}"
                                  shape="circle"></p-avatar>
                    </a>

                    <ul class="layout-topbar-action-panel p-shadow-6"
                        [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.activeTopbarItem === 'profile'">

                        <li class="layout-topbar-action-item">
                            <a class="p-d-flex p-flex-row p-ai-center"
                               pRipple
                               (click)="logout()">
                                <i class="pi pi-power-off"
                                   style="color: red; font-size: large;"
                                   [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Çıkış Yap</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="p-d-flex p-flex-row p-ai-center"
                               pRipple
                               (click)="changePassw()">
                                <i class="pi pi-info-circle"
                                   style="color: blue; font-size: large;"
                                   [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Hesap Bilgilerim</span>
                            </a>
                        </li>

                    </ul>
                </li>
                <!-- <li class="layout-topbar-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onRightMenuButtonClick($event)" pRipple>
                        <i class="pi fs-large" [ngClass]="{'pi-arrow-left': !app.isRTL, 'pi-arrow-right': app.isRTL}"></i>
                    </a>
                </li> -->
            </ul>
        </div>
    </div>
</div>
