import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AnnouncementEntity } from 'src/app/modules/announcement-management/announcement/model/announcement.model';

@Component({
  selector: 'app-dashboard-announcement-detail',
  templateUrl: './dashboard-announcement-detail.component.html',
  styleUrls: ['./dashboard-announcement-detail.component.css']
})
export class DashboardAnnouncementDetailComponent implements OnInit {

  public announceEntity: AnnouncementEntity;
  display: boolean = false;

  constructor( public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.announceEntity = this.config.data;
  }
}












