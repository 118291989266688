import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent implements OnInit, OnDestroy {
  @Input() formControler: UntypedFormControl;
  errorText: string;
  characterLimitation: string;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();;
  constructor() { }


  ngOnInit(): void {
    this.formControler.errors ? this.createErrorMessage() : this.errorText = undefined;
    this.formControler.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.formControler.errors ? this.createErrorMessage() : this.errorText = undefined;
    })
  }

  createErrorMessage() {
    switch (true) {
      case this.formControler.hasError('required'):
        this.errorText = 'Bu Alan Zorunludur.';
        break;
      case this.formControler.hasError('email'):
        this.errorText = 'Email Hatalı format';
        break;
        case this.formControler.hasError('backerr'):
          this.errorText = ` ${this.formControler.errors.message} `;
          break;
      case this.formControler.hasError('minlength'):
        this.errorText = `En az girilmesi gereken uzunluk ${this.formControler.errors.minlength.requiredLength} karakter olmalıdır `;
        break;
      case this.formControler.hasError('maxlength'):
        this.errorText = `En fazla Uzunluk ${this.formControler.errors.minlength.requiredLength} karakter olmalıdır.`;
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
  }

}
