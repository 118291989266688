import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ActionResponse } from "src/app/base/models/base-response.model";
import { GeneralSearchModel } from "src/app/base/models/response.model";
import { environment } from "src/environments/environment";
import { selectNumberModel } from "../model/select-model";

@Injectable()
export class GlobalParamService {
    apiUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    async getMyAgencyList<T>(): Promise<ActionResponse<selectNumberModel>> {
      return await this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetMyAgencyList`).toPromise();
    }
    async getMyCustomerList<T>(agencyId : number): Promise<ActionResponse<selectNumberModel>> {
      return this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetMyCustomerList/${agencyId}`).toPromise();
    }

    async getCityPromise<T>(): Promise<ActionResponse<selectNumberModel>> {
      return await this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetCityList`).toPromise();
    }
    SearchMyCompanies<T>(searcModel: GeneralSearchModel): Observable<ActionResponse<T>> {
      return this.http.post<ActionResponse<T>>(`${this.apiUrl}GlobalParam/SearchMyCompanies/`, searcModel);
    }
    async getDistrictsPromise<T>(cityId : number): Promise<ActionResponse<T>> {
      return await this.http.get<ActionResponse<T>>(`${this.apiUrl}GlobalParam/GetDistrictList/${cityId}`).toPromise();
    }
    async getAllTaxOfficePromise<T>(): Promise<ActionResponse<selectNumberModel>> {
      return await this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetTaxOfficeList/`).toPromise();
    }
    async getCustomerUsersByCustomerIdPromise<T>(customerId : number ): Promise<ActionResponse<selectNumberModel>> {
      return await this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetCustomerUsersByCustomerId/${customerId}`).toPromise();
    }
    getRoleList<T>(): Observable<ActionResponse<T>> {
      return this.http.get<ActionResponse<T>>(`${this.apiUrl}GlobalParam/GetRoleList`);
    }

    async GetApproverList<T>( ): Promise<ActionResponse<string>> {
      return this.http.get<ActionResponse<string>>(`${this.apiUrl}GlobalParam/GetApproverList/`).toPromise();
    }
    async GetControllerList<T>( ): Promise<ActionResponse<string>> {
      return this.http.get<ActionResponse<string>>(`${this.apiUrl}GlobalParam/GetContollerList/`).toPromise();
    }
    async GetMyCustomerList2<T>(): Promise<ActionResponse<selectNumberModel>> {
      return this.http.get<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/GetMyCustomerList2/`).toPromise();
    }

    async SearchMyCustomers<T>(searchModel:GeneralSearchModel): Promise<ActionResponse<selectNumberModel>> {
      return  this.http.post<ActionResponse<selectNumberModel>>(`${this.apiUrl}GlobalParam/SearchMyCustomers`,searchModel).toPromise();
    }
    async GetSicilNoXml<T>(companyId : number ): Promise<ActionResponse<T>> {
      return await this.http.get<ActionResponse<T>>(`${this.apiUrl}GlobalParam/GetSicilNoXml/${companyId}`).toPromise();
    }
    async GetCompSicilInfo<T>(companyId : number ): Promise<ActionResponse<T>> {
      return await this.http.get<ActionResponse<T>>(`${this.apiUrl}GlobalParam/GetCompSicilInfo/${companyId}`).toPromise();
    }

    public base64ToArrayBuffer(base64: any): ArrayBuffer {
      var binaryString = window.atob(base64);
      var len = binaryString.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
    public base64ToArrayBufferArr(base64Arr: any[]): ArrayBuffer[] {
      let returnData:ArrayBuffer[]=[];
      base64Arr.forEach(base64 => {
        var binaryString = window.atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        returnData.push(bytes.buffer);
      });
      return returnData;
    }
}
