import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUserToken = localStorage.getItem('token');
    if (state.url.includes('/demomanagement/democalc/')) {
      return true;
    }
    if (currentUserToken) {
      return true;
    } else {
      this.router.navigate([`/authentication/login`], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
