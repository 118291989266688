import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AlertService } from '../services/alert.service';
import { KendoModule } from './kendo.module';
import { PrimeNgModule } from './primeng.module';
import { MaintananceResolverService } from '../services/maintanance-resolver.service';
import { MaintananceActionButtonComponent } from '../component/maintanance/maintanance-action-button/maintanance-action-button.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroupService } from '../services/form-group.service';
import { EditGridComponent } from '../component/grid/edit-grid/edit-grid.component';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { InquiryResolverService } from '../services/inquiry-resolver.service';
import { ModuleStateService } from '../services/module-state.service';
import { PipesModule } from '../pipe/pipes.module';
import { ScrollTopButtonComponent } from '../scroll-top-button/scroll-top-button.component';
import { DialogCommentComponent } from '../component/maintanance/dialog-comment/dialog-comment.component';
import { MultiFileUploadComponent } from '../component/file-managament/multi-file-upload/multi-file-upload.component';
import { FileUploadComponent } from '../component/file-managament/file-upload/file-upload.component';


@NgModule({
    declarations: [
        MaintananceActionButtonComponent,
        EditGridComponent,
        FileUploadComponent,
        MultiFileUploadComponent,
        ValidationMessageComponent,
        ScrollTopButtonComponent,
        DialogCommentComponent


    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        KendoModule,
        PrimeNgModule,
        PipesModule,

    ],
    providers: [
        AlertService,
        MessageService,
        HttpClientModule,
        MaintananceResolverService,
        InquiryResolverService,
        MessageService,
        ConfirmationService,
        DialogService,
        DynamicDialogConfig,
        DynamicDialogRef,
        FormGroupService,
        ModuleStateService,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        KendoModule,
        PrimeNgModule,
        PipesModule,

        FileUploadComponent,
        MultiFileUploadComponent,
        MaintananceActionButtonComponent,
        EditGridComponent,
        ValidationMessageComponent,
        ScrollTopButtonComponent,

    ]
})
export class SharedModule {
    constructor() {
    }
}
