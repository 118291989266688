<div class="p-grid">
        <div class="p-col-12">
                <p-toolbar fxLayout="row wrap" fxLayoutAlign="space-between center">

                        <div>
                                <button pButton pRipple
                                        *ngIf="!maintenanceActionButtonConfig.canManageForm || maintenanceActionButtonConfig.mode === manipulationMode.create"
                                        type="button" label="Listeye Dön" (click)="goBack.emit()"
                                        icon="pi pi-angle-left" class="p-button-info p-button-text p-mr-2 p-mb-2">
                                </button>
                        </div>

                        <div class="custom-message" fxLayout="row" fxLayoutAlign="space-between center" #messageContent
                                *ngIf="hasMessageContent">
                                <i class="pi pi-bell mr-5"></i>
                                <ng-content></ng-content>
                        </div>

                        <div>
                                <ng-container
                                        *ngFor="let item of maintenanceActionButtonConfig.availableActions; let i = index">
                                        <button pButton
                                                *ngIf="item.isAvaliable && !maintenanceActionButtonConfig.canManageForm"
                                                pTooltip="{{item.actionName}}" tooltipPosition="top"
                                                icon="{{item.icon}}" pRipple type="button" label="{{item.label}}"
                                                (click)="actionHandlerClick(item)" class="{{item.btnClass}}"></button>
                                </ng-container>



                                <button pButton pRipple type="button"
                                        *ngIf="maintenanceActionButtonConfig.isEntityEditable && !maintenanceActionButtonConfig.canManageForm && editAccess"
                                        label="Düzenle" icon="pi pi-pencil" pTooltip="Düzenle" tooltipPosition="top"
                                        (click)="editClicked()" class="p-button-info p-button-text p-mr-2">
                                </button>

                                <button pButton pRipple type="button" label="Kaydet"
                                        *ngIf="maintenanceActionButtonConfig.canManageForm" pTooltip="Save"
                                        tooltipPosition="top" (click)="onSave()" icon="pi pi-save"
                                        class="p-button-text p-mr-2">
                                </button>
                                <button pButton pRipple type="button" style="color: #2196f3;"
                                        *ngIf="maintenanceActionButtonConfig.mode === manipulationMode.create && !maintenanceActionButtonConfig.form.pristine"
                                        label="Temizle" icon="pi pi-spinner" (click)="clear()"
                                        class="p-button-text p-mr-2">
                                </button>

                                <button pButton pRipple
                                        *ngIf="maintenanceActionButtonConfig.canManageForm && maintenanceActionButtonConfig.mode === manipulationMode.update"
                                        icon="pi pi-times" type="button" pTooltip="Vazgeç" tooltipPosition="top"
                                        class="p-button-rounded p-button-danger p-button-text" (click)="giveUpForm()">
                                </button>

                        </div>

                </p-toolbar>

                <div class="dynamic-buttons" *ngIf="entity.isLoaded">
                        <button *ngFor="let action of maintenanceActionButtonConfig.maintenanceSettings.rowActions"
                                pButton pRipple type="button" [label]="action.label" [icon]="action.icon"
                                tooltipPosition="top" styleClass="p-button-sm" class="p-button-info p-button-text"
                                (click)="execHandler(action.actionName)">
                        </button>
                </div>



                <form name="formd" [formGroup]="formd">
                        <p-dialog [(visible)]="actDialog" [style]="{width: '450px'}" header="Açıklama" [modal]="true"
                                styleClass="p-fluid">

                                <div class="p-field">
                                        <label for="description">Açıklama</label>

                                        <textarea id="description" formControlName="Descr" pInputTextarea rows="5"
                                                cols="15"></textarea>

                                        <div *ngIf="formd.controls.Descr.errors" class="invalid-feedback">
                                                <p-message *ngIf="formd.controls.Descr.errors.required" severity="error"
                                                        text="Açıklama alanı boş olamaz"></p-message>
                                                <p-message *ngIf="formd.controls.Descr.errors.minlength" severity="error"
                                                        text="En Az 10 karakter giriniz"></p-message>

                                        </div>

                                </div>
                                <ng-template pTemplate="footer">
                                        <button pButton pRipple label="Vazgeç" icon="pi pi-times" class="p-button-text"
                                                (click)="hideDialog()"></button>
                                        <button pButton  [disabled]="formd.invalid"   pRipple label="{{actBtnDescr}}" icon="pi pi-check"
                                                class="p-button-text" (click)="execHandlerWithData()"></button>
                                </ng-template>
                        </p-dialog>

                </form>



        </div>
</div>